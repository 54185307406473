import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DynamicTable from "../../Common/NewDataTable";
import Loader from "../../Common/Loader"; // Ensure you have a Loader component
import axios from "axios";
import { BASE_URL } from "../../Common/apiURL";
import { useNavigate } from "react-router-dom";
import Table from "../../Common/SearchFilterTable";

const groupDataByDocID = (data) => {
  let groupedData = [];
  let currentDocID = null;
  let serialNumber = 0; // Initialize a serial number

  data.forEach((item) => {
    if (item.DocID !== currentDocID) {
      // Reset the serial number for each new DocID
      serialNumber = 1; // Start numbering from 1 for new group
      groupedData.push({
        isGroupHeader: true,
        DocID: item.DocID, // Optionally store the DocID for grouping purposes
      });
      currentDocID = item.DocID;
    } else {
      // Increment the serial number for same DocID
      serialNumber++;
    }

    // Add the actual data row with the serial number
    groupedData.push({ ...item, serialNumber });
  });

  return groupedData;
};

const DayBook = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [showData, setShowData] = useState(false); // To control table display
  const [isLoading, setIsLoading] = useState(false); // Loader state
  const [showLoader, setShowLoader] = useState(false); // To control loader display
  // Basic Information For API's
  let targetRoute = "Day Book";
  let menuValue = JSON.parse(localStorage.getItem("Menu"));
  let Depot = JSON.parse(localStorage.getItem("branches"));
  let token = localStorage.getItem("token");
  let selectedLocation = localStorage.getItem("selectedLocation");
  const navigate = useNavigate();

  const targetPage = menuValue.find((item) => item.FormName === targetRoute);
  console.log(targetPage.FormName);
  // const token = Cookies.get("token");
  var UserName;
  var DPCode;
  var BCode;
  Depot.forEach((depot) => {
    if (depot.BranchName === selectedLocation) {
      BCode = depot.BranchCode;
    }
  });
  const itemFromLocalStorage = JSON.parse(localStorage.getItem("userInfo"));
  UserName = itemFromLocalStorage[0]?.UserID;
  console.log(itemFromLocalStorage);
  DPCode = itemFromLocalStorage[0]?.DpCode;
  const config = {
    headers: {
      "Content-Type": "application/json",
      token: token,
    },
  };

  const [rows, setRows] = useState([]);

  // Handle the show button click
  const handleShowData = async (e) => {
    e.preventDefault();
    const formattedFromDate = startDate
      .toISOString()
      .slice(0, 10)
      .replace(/-/g, "");
    const formattedToDate = endDate
      .toISOString()
      .slice(0, 10)
      .replace(/-/g, "");

    const requestBody = {
      Depot: DPCode,
      BranchCode: BCode,
      Username: UserName,
      FormKey: targetPage.FormKey,
      PartyName: "", // Assuming party name is not needed as we are filtering by agent
      AgentName: "",
      ItemGroup: "",
      ItemName: "",
      Date1: formattedFromDate,
      Date2: formattedToDate,
      RptCode: 0,
    };
    // console.log(requestBody);

    try {
      const response = await axios.post(
        `${BASE_URL}/dashboard/web-reports`,
        requestBody,
        config
      );
      console.log(response.data.data);
      const groupedData = groupDataByDocID(response.data.data); // Group data by DocID
      setRows(groupedData);
    } catch (error) {
      alert("Something went wrong!!!", error);
    } finally {
      setShowLoader(false);
    }
  };

  const columns = [
    {
      Header: "Vou. No",
      accessor: "VouNo",
      Cell: ({ value }) => (
        <div style={{ textAlign: "center", width: "70px" }}>{value}</div>
      ),
      isInitiallyVisible: true,
    },
    {
      Header: " Date",
      accessor: "VDate",
      Cell: ({ value }) => (
        <div>{value ? new Date(value).toLocaleDateString("en-GB") : ""}</div>
      ),
      isInitiallyVisible: true,
    },

    // {
    //   Header: "Doc ID",
    //   accessor: "DocID",
    //   Cell: ({ row }) => (
    //     <div style={{ textAlign: "left", width: "120px" }}>
    //       {row.original.isGroupHeader ? "" : row.original.DocID}
    //     </div>
    //   ),

    //   isInitiallyVisible: false,
    // },
    {
      Header: "Particulars",
      accessor: "LName",
      Cell: ({ value }) => (
        <div style={{ textAlign: "left", width: "200px" }}>{value}</div>
      ),
      headerStyle: { textAlign: "center" }, // Center-align header
      isInitiallyVisible: true,
    },
    {
      Header: "Type",
      accessor: "VType",
      Cell: ({ value }) => (
        <div style={{ textAlign: "center", width: "50px" }}>{value}</div>
      ),
      isInitiallyVisible: true,
    },
    {
      Header: "Depot",
      accessor: "BranchName",
      isInitiallyVisible: false,
      Cell: ({ value }) => <div style={{ textAlign: "center" }}>{value}</div>,
    },
    {
      Header: "Username",
      accessor: "UserName",
      Cell: ({ value }) => <div style={{ textAlign: "center" }}>{value}</div>,
      isInitiallyVisible: false,
    },
    {
      Header: "Amount (Dr)",
      accessor: "DrAmt",
      Cell: ({ value }) => (
        <div style={{ textAlign: "right" }}>
          {typeof value === "number" ? value.toFixed(2) : value}
        </div>
      ),
      isInitiallyVisible: true,
    },
    {
      Header: "Amount (Cr)",
      accessor: "CrAmt",
      Cell: ({ value }) => (
        <div style={{ textAlign: "right" }}>
          {typeof value === "number" ? value.toFixed(2) : value}
        </div>
      ),
      isInitiallyVisible: true,
    },
    {
      Header: "Ledger",
      accessor: "DLName",
      Cell: ({ value }) => (
        <div style={{ textAlign: "left", width: "200px" }}>{value}</div>
      ),
      isInitiallyVisible: false,
    },

    {
      Header: "Remarks",
      accessor: "Remark", // Keep an accessor if you want sorting based on Remark
      Cell: ({ row }) => {
        const { Remark, Remark1, Remark2 } = row.original;
        // Combine the remarks, conditionally showing only those with values
        return (
          <div style={{ textAlign: "left", width: "200px" }}>
            {[Remark, Remark1, Remark2].filter(Boolean).join(" ")}
          </div>
        );
      },
      isInitiallyVisible: true,
    },
    {
      Header: "Transaction Date",
      accessor: "TranDate",
      Cell: ({ value }) => {
        if (!value) return ""; // Handle null or undefined

        // Create a new Date object from the UTC date string
        const utcDate = new Date(value);

        // Convert UTC to IST (UTC +5:30)
        const istOffset = 5 * 60 + 30; // IST offset in minutes
        const istDate = new Date(utcDate.getTime() + istOffset * 60 * 1000); // Add offset in milliseconds

        // Increase the hour by 1
        istDate.setHours(istDate.getHours() + 1);

        // Format options for displaying date and time
        const options = {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: false, // Use 24-hour format
        };

        return (
          <div style={{ width: "150px" }}>
            {istDate.toLocaleString("en-GB", options)}
          </div>
        );
      },
      isInitiallyVisible: true,
    },
  ];
  console.log(rows);
  return (
    <div className='p-5'>
      {isLoading && <Loader />} {/* Show loader when loading */}
      <h2 className='text-sm font-semibold mb-4'>Day Book</h2>
      <form className='grid grid-cols-3 gap-2'>
        {/* Datepicker */}
        <div className='flex flex-col'>
          <label className='block font-medium text-sm'>From</label>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            className='w-full p-1 border rounded text-xs'
            maxDate={new Date()}
            dateFormat='dd/MM/yyyy'
          />
        </div>

        <div className='flex flex-col'>
          <label className='block font-medium text-sm'>To</label>
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            className='w-full p-1 border rounded text-xs'
            dateFormat='dd/MM/yyyy'
            maxDate={new Date()}
          />
        </div>

        {/* Show Button */}
        <div className='w-full'>
          <button
            type='button'
            onClick={handleShowData}
            className='px-4 py-1 bg-green-600 text-white rounded mt-4'
          >
            Show
          </button>
        </div>
      </form>
      {/* Conditionally render the Stock Summary Table */}
      <div className='mt-5'>
        <Table columns={columns} data={rows} />
      </div>
    </div>
  );
};

export default DayBook;
