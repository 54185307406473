import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  FaHome,
  FaBalanceScale,
  FaUserCheck,
  FaClipboardList,
  FaRegListAlt,
  FaUsers,
  FaCubes,
  FaBuilding,
  FaReceipt,
  FaTags,
  FaFileInvoice,
  FaListAlt,
  FaBox,
  FaMoneyCheckAlt,
  FaPrint,
  FaLock,
  FaCalendarAlt,
} from "react-icons/fa";
import { FiDollarSign, FiGrid, FiLayers, FiXSquare } from "react-icons/fi";
import { MdInventory2 } from "react-icons/md";
import YourFavorites from "./YourFavorites";

// Define your icon mapping with unique colors
const iconMapping = {
  103: <FiLayers color='#FF5733' />, // Group Master - Orange
  104: <FaUserCheck color='#33FF57' />, // Ledger Master - Green
  119: <FaUserCheck color='#3357FF' />, // Agent Master - Blue
  120: <FaBalanceScale color='#FF33A1' />, // Transport Master - Pink
  105: <FaUsers color='#33FFF5' />, // Party Master - Aqua
  106: <FaBox color='#FF8C33' />, // Product Master - Light Orange
  107: <FaTags color='#FFC300' />, // Brand Master - Yellow
  108: <FaListAlt color='#DAF7A6' />, // Unit Master - Light Green
  116: <FaClipboardList color='#C70039' />, // Finish Master - Red
  109: <FaCubes color='#900C3F' />, // Item Group Master - Dark Red
  115: <FiGrid color='#581845' />, // Sub Group Master - Purple
  110: <FaBox color='#2ECC71' />, // Item Master - Green
  111: <FaFileInvoice color='#3498DB' />, // GST Setup - Light Blue
  112: <FaMoneyCheckAlt color='#1ABC9C' />, // Tax Master - Teal
  113: <FaReceipt color='#9B59B6' />, // GST Master - Violet
  114: <FaListAlt color='#E74C3C' />, // HSN Master - Orange Red
  117: <FiDollarSign color='#F39C12' />, // Price List Setup - Dark Yellow
  118: <FiDollarSign color='#D35400' />, // Price List Master - Dark Orange
  121: <FaBuilding color='#2980B9' />, // Company Price List - Navy Blue
  202: <FiXSquare color='#8E44AD' />, // Purchase Voucher - Purple
  205: <FaRegListAlt color='#F1C40F' />, // Sales - Gold
  206: <FaFileInvoice color='#27AE60' />, // Tax Invoice - Green
  211: <FaClipboardList color='#2980B9' />, // Billing Information Updation - Blue
  210: <FaBalanceScale color='#16A085' />, // LR Entry - Teal
  213: <FiXSquare color='#C0392B' />, // Bill Cancellation - Dark Red
  303: <FaListAlt color='#7D3C98' />, // Purchase Register - Purple
  302: <FaListAlt color='#E74C3C' />, // Sales Register - Red
  401: <FaPrint color='#34495E' />, // Sales Document Printing - Gray Blue
  402: <FaPrint color='#BDC3C7' />, // Invoice Printing - Light Gray
  403: <MdInventory2 color='#7F8C8D' />, // Inventory - Gray
  404: <MdInventory2 color='#95A5A6' />, // Stock Summary - Light Gray
  1102: <FaLock color='#2C3E50' />, // Change Password - Dark Gray
  1103: <FaCalendarAlt color='#8E44AD' />, // Change Period - Purple
};

const defaultIconSize = 20;

const QuickLinks = () => {
  const [formDetails, setFormDetails] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const storedFormDetails = localStorage.getItem("Menu");
    if (storedFormDetails) {
      setFormDetails(JSON.parse(storedFormDetails));
    }
  }, []);

  const quickLinks = formDetails
    .filter((item) => item.SubCategory === 0)
    .sort(
      (a, b) => a.FormName.split(" ").length - b.FormName.split(" ").length
    );

  const filteredLinks = quickLinks.filter((item) =>
    item.FormName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className=' p-3 rounded-2xl bg-white shadow-2xl body'>
      <div className='flex items-center justify-between mb-2'>
        <h2 className='text-sm font-semibold underline'>Quick Links</h2>
        <input
          type='text'
          placeholder='Search...'
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className='p-1 border border-gray-300 rounded-lg w-1/3 lg:w-1/4 text-xs'
        />
      </div>
      <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 text-xs body'>
        {filteredLinks.map((item) => (
          <Link
            key={item.FormKey}
            to={`/${item.FormName.replace(/\s+/g, "-").toLowerCase()}`}
            className='flex items-center p-2 border border-gray-200 rounded-lg shadow-md hover:bg-[#000] duration-700 hover:text-white text-gray-900'
          >
            {React.cloneElement(
              iconMapping[item.FormKey] || <FaHome color='#000000' />,
              { size: defaultIconSize }
            )}
            <span className='text-xs ml-2'>{item.FormName}</span>
          </Link>
        ))}
      </div>
      {/*<YourFavorites />*/}
    </div>
  );
};

export default QuickLinks;
