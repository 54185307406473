import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { FaEdit, FaTrash, FaCaretDown } from "react-icons/fa";
import {
  purchaseVoucherFormFields,
  initialData,
  itemDetailsFields,
} from "../../../data"; // Ensure correct path
import DatePicker from "react-datepicker"; // DatePicker component
import "react-datepicker/dist/react-datepicker.css"; // Datepicker styles

const PurchaseVoucherLookUp = () => {
  const [data, setData] = useState(initialData);
  const [editId, setEditId] = useState(null); // To track the row being edited
  const [formData, setFormData] = useState({}); // Form data for edit
  const [visibleColumns, setVisibleColumns] = useState({}); // Track visible columns
  const [dropdownOpen, setDropdownOpen] = useState(false); // Track dropdown visibility

  // Filters
  const [purchaseType, setPurchaseType] = useState(""); // Purchase registered/unregistered
  const [selectedSupplier, setSelectedSupplier] = useState(""); // Supplier dropdown
  const [selectedItemName, setSelectedItemName] = useState(""); // Item name dropdown
  const [fromDate, setFromDate] = useState(null); // From date
  const [toDate, setToDate] = useState(null); // To date

  // Initialize visible columns
  useEffect(() => {
    const initialVisible1 = purchaseVoucherFormFields.reduce((acc, field) => {
      acc[field.name] = true; // All columns visible by default
      return acc;
    }, {});
    const initialVisible2 = itemDetailsFields.reduce((acc, field) => {
      acc[field.name] = true; // All columns visible by default
      return acc;
    }, {});
    setVisibleColumns({
      "S No": true, // Ensure serial number is always visible
      ...initialVisible1,
      ...initialVisible2,
      Actions: true, // Ensure Actions column is always visible
    });
  }, []);

  // Edit handler
  const handleEdit = (row) => {
    setEditId(row.id); // Set the ID of the row being edited
    setFormData({ ...row }); // Set current row data in form
  };

  // Save the edited row
  const handleSave = () => {
    const updatedData = data.map((row) =>
      row.id === editId ? { ...row, ...formData } : row
    );
    setData(updatedData); // Update data
    setEditId(null); // Exit edit mode
  };

  // Delete handler
  const handleDelete = (rowId) => {
    const updatedData = data.filter((row) => row.id !== rowId);
    setData(updatedData);
  };

  // Handle form data change for editing
  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle column visibility change
  const handleColumnVisibilityChange = (e) => {
    const { name, checked } = e.target;
    setVisibleColumns((prev) => ({ ...prev, [name]: checked }));
  };

  // Filtered data based on the filters selected
  const filteredData = data.filter((row) => {
    const isPurchaseTypeMatch =
      purchaseType === "" || row.purchaseType === purchaseType;
    const isSupplierMatch =
      selectedSupplier === "" || row.supplierName === selectedSupplier;
    const isItemNameMatch =
      selectedItemName === "" || row.itemName === selectedItemName;
    const isFromDateMatch =
      !fromDate || new Date(row.date) >= new Date(fromDate);
    const isToDateMatch = !toDate || new Date(row.date) <= new Date(toDate);

    return (
      isPurchaseTypeMatch &&
      isSupplierMatch &&
      isItemNameMatch &&
      isFromDateMatch &&
      isToDateMatch
    );
  });

  // Dynamically create columns based on form fields and visibility
  const columns = [
    {
      name: "S No",
      selector: (row, index) => index + 1, // Generate serial number dynamically
      sortable: true,
      width: "80px",
      cell: (row, index) => <div className='text-center py-2'>{index + 1}</div>,
    },
    {
      name: "Actions",
      cell: (row) =>
        editId === row.id ? (
          <button
            onClick={handleSave}
            className='text-green-500 px-2 py-1 border rounded'
          >
            Save
          </button>
        ) : (
          <>
            <FaEdit
              className='text-blue-500 cursor-pointer mx-2'
              onClick={() => handleEdit(row)}
            />
            <FaTrash
              className='text-red-500 cursor-pointer'
              onClick={() => handleDelete(row.id)}
            />
          </>
        ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      omit: !visibleColumns["Actions"],
    },
    ...purchaseVoucherFormFields.map((field) => ({
      name: field.label,
      selector: (row) =>
        editId === row.id ? (
          <input
            type={field.type}
            name={field.name}
            value={formData[field.name] || ""}
            onChange={handleInputChange}
            className='border p-1 rounded'
          />
        ) : (
          row[field.name] || "" // Provide a default value to avoid undefined
        ),
      sortable: true,
      omit: !visibleColumns[field.name],
    })),
    ...itemDetailsFields.map((field) => ({
      name: field.label,
      selector: (row) =>
        editId === row.id ? (
          <input
            type={field.type}
            name={field.name}
            value={formData[field.name] || ""}
            onChange={handleInputChange}
            className='border p-1 rounded'
          />
        ) : (
          row[field.name] || "" // Provide a default value to avoid undefined
        ),
      sortable: true,
      omit: !visibleColumns[field.name],
    })),
  ];
  console.log(data);
  return (
    <div className='p-4 bg-gray-100 rounded-xl shadow-md'>
      {/* Filter Section */}
      <div className='grid grid-cols-2 md:grid-cols-5 mb-5 gap-4'>
        <div>
          <label className='block font-medium text-sm mb-1'>
            Purchase Type
          </label>
          <select
            className='border p-1 text-xs rounded'
            value={purchaseType}
            onChange={(e) => setPurchaseType(e.target.value)}
          >
            <option value=''>All</option>
            <option value='Registered'>Registered</option>
            <option value='Unregistered'>Unregistered</option>
          </select>
        </div>

        <div>
          <label className='block font-medium text-sm mb-1'>Supplier</label>
          <select
            className='border p-1 text-xs rounded'
            value={selectedSupplier}
            onChange={(e) => setSelectedSupplier(e.target.value)}
          >
            <option value=''>All Suppliers</option>
            {/* Map suppliers dynamically */}
            {data.map((d) => (
              <option key={d.supplierName} value={d.supplierName}>
                {d.supplierName}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label className='block font-medium text-sm mb-1'>Item Name</label>
          <select
            className='border p-1 text-xs rounded'
            value={selectedItemName}
            onChange={(e) => setSelectedItemName(e.target.value)}
          >
            <option value=''>All Items</option>
            {/* Map item names dynamically */}
            {data.map((d) => (
              <option key={d.itemName} value={d.itemName}>
                {d.itemName}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label className='block font-medium text-sm mb-1'>From</label>
          <DatePicker
            selected={fromDate}
            onChange={(date) => setFromDate(date)}
            className='border p-1 text-xs rounded'
            placeholderText='Select From Date'
          />
        </div>

        <div>
          <label className='block font-medium text-sm mb-1'>To</label>
          <DatePicker
            selected={toDate}
            onChange={(date) => setToDate(date)}
            className='border p-1 text-xs rounded'
            placeholderText='Select To Date'
          />
        </div>
      </div>

      {/* DataTable Section */}
      <DataTable
        columns={columns}
        data={filteredData}
        pagination
        responsive
        customStyles={{
          rows: {
            style: {
              fontSize: "14px", // Adjust font size for rows
            },
          },
        }}
      />
    </div>
  );
};

export default PurchaseVoucherLookUp;
