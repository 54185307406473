import React from "react";
import Navbar from "./Navbar";
import Hero from "./Hero";
import Footer from "./Footer";
import Features from "./Features";
import AboutUs from "./AboutUs";
import PricingPlans from "./PricingPlans";
import FAQPage from "./FAQ";
import ContactPage from "./Contact";
import WhyChooseUs from "./WhyChooseUs";
import ScrollToTop from "./ScrollToTop";

const index = () => {
  return (
    <div>
      <Navbar />
      {/* Hero Section */}
      <section id='home' className='flex items-center justify-center '>
        <Hero />
      </section>

      {/* Features Section */}
      <section id='features' className='flex items-center justify-center'>
        <Features />
      </section>
      <section id='aboutUs' className='flex items-center justify-center'>
        <AboutUs />
      </section>
      <section id='whyChooseUs' className='flex items-center justify-center'>
        <WhyChooseUs />
      </section>
      <section id='pricingPlans' className='flex items-center justify-center'>
        <PricingPlans />
      </section>
      <section id='faq' className='flex items-center justify-center'>
        <FAQPage />
      </section>
      <section id='contact' className='flex items-center justify-center'>
        <ContactPage />
      </section>
      {/* Product Section */}
      <section
        id='product'
        className=' bg-white flex items-center justify-center'
      ></section>
      <Footer />
      <ScrollToTop />
    </div>
  );
};

export default index;
