// hooks/useTrackPageVisits.js

import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const useTrackPageVisits = () => {
  const location = useLocation();

  useEffect(() => {
    const pageName = location.pathname;
    const visitedPages = JSON.parse(localStorage.getItem("visitedPages")) || [];

    // Remove the page if it already exists
    const updatedPages = visitedPages.filter((page) => page.name !== pageName);

    // Add the new page entry
    updatedPages.unshift({
      name: pageName,
      timestamp: new Date().toISOString(),
    });

    // Keep only the last 5 entries
    if (updatedPages.length > 11) {
      updatedPages.pop();
    }

    localStorage.setItem("visitedPages", JSON.stringify(updatedPages));
  }, [location]);
};

export default useTrackPageVisits;
