import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import DashBoard from "./Pages/DashBoard";

function App() {
  console.log(process.env.NODE_ENV);
  return (
    <Router>
      <div className='bg-[#ffffff]'>
        <DashBoard />
      </div>
    </Router>
  );
}

export default App;
