import React, { useEffect, useState } from "react";
import {
  Route,
  Routes,
  useLocation,
  Navigate,
  useNavigate,
} from "react-router-dom";
import Header from "../Components/Header";
import Navbar from "../Components/Navbar";
import Home from "./HomePage";
import Login from "./Login";
import PageNotFound from "./404";
import SalesRegister from "./BillingForms/Display/SalesRegister";
import StockSummary from "./BillingForms/Reports/Inventory/StockSummary";
import useTrackPageVisits from "../utils/useTrackPageVisits";
import AgentMaster from "./BillingForms/AgentMaster/AgentMaster";
import PartyMaster from "./BillingForms/PartyMaster/PartyMaster";
import TransportMaster from "./BillingForms/TransportMaster/TransportMaster";
import BrandMaster from "./BillingForms/BrandMaster/BrandMaster";
import UnitMaster from "./BillingForms/UnitMaster/UnitMaster";
import FinishMaster from "./BillingForms/FinishMaster/FinishMaster";
import SubGroupMaster from "./BillingForms/SubGroupMaster/SubGroupMaster";
import ItemGroupMaster from "./BillingForms/ItemGroupMaster/ItemGroupMaster";
import ItemMaster from "./BillingForms/ItemMaster/ItemMaster";
import TaxMaster from "./BillingForms/TaxMaster/TaxMaster";
import GSTMaster from "./BillingForms/GSTMaster/GSTMaster";
import HSNMaster from "./BillingForms/HSNMaster/HSNMaster";
import PriceListMaster from "./BillingForms/PriceListMaster/PriceListMaster";
import CompanyPriceListMaster from "./BillingForms/CompanyPriceListMaster/CompanyPriceListMaster";
import ChangePeriod from "./BillingForms/Administrator/ChangePeriod";
import ChangePassword from "./BillingForms/Administrator/ChangePassword";
import InvoicePrinting from "./BillingForms/Reports/InvoicePrinting";
import PurchaseRegister from "./BillingForms/Display/PurchaseRegister";
import PurchaseVoucher from "./BillingForms/Transaction/Purchases/Purchase/PurchaseVoucher";
import BillCancellation from "./BillingForms/Transaction/Sales/BillCancellation";
import LREntry from "./BillingForms/Transaction/Sales/LREntry";
import TaxInvoice from "./BillingForms/Transaction/Sales/TaxInvoice";
import axios from "axios";
import { BASE_URL } from "./Common/apiURL";
import DayBook from "./BillingForms/Accounts/DayBook";
import LandingPage from "./LandingPage/index";
import CreditorsList from "./BillingForms/Accounts/CreditorsList";
import DebtorsList from "./BillingForms/Accounts/DebtorsList";
import Ledger from "./BillingForms/Accounts/Ledger";

const DashBoard = () => {
  const location = useLocation();
  useTrackPageVisits(); // Use the hook to track page visits

  const authToken = localStorage.getItem("token");
  const [dashboardData, setDashboardData] = useState(null);
  const token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("userInfo"));
  const navigate = useNavigate();

  const fetchDashboardData = async () => {
    try {
      const UserId = user[0]?.UserID;
      const Depo = user[0]?.DpCode;
      const requestBody = { UserId, Depo };
      const config = {
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
      };

      const response = await axios.post(
        `${BASE_URL}/dashboard/data`,
        requestBody,
        config
      );

      if (response.data.success) {
        setDashboardData(response.data.data);
      } else {
        console.log("Failed to fetch dashboard data");
      }
    } catch (err) {
      console.error(
        "Error fetching dashboard data:",
        err.response.data.success
      );
      if (
        err.response &&
        err.response.data &&
        err.response.data.success === false
      ) {
        alert("Your token has expired ! Please login again.");
      }
    }
  };

  useEffect(() => {
    if (authToken) {
      fetchDashboardData();
    }
  }, [authToken]);

  if (!authToken) {
    return (
      <Routes>
        <Route path='/' element={<LandingPage />} />
        <Route path='/login' element={<Login />} />
        <Route path='*' element={<Navigate to='/' />} />
      </Routes>
    );
  }

  return (
    <div className='flex flex-col min-h-screen'>
      <Header data={dashboardData} />
      <div className='flex flex-1 bg-[#FFFFFF]'>
        <Navbar />
        <main className='flex-1 mt-[10%] md:mt-[6%] lg:mt-[3.4%] lg:ml-[4%] w-full lg:w-[50%]'>
          <Routes>
            <Route path='/dashboard' element={<Home data={dashboardData} />} />

            {/*Master Routes*/}
            <Route path='/agent-master' element={<AgentMaster />} />
            <Route path='/party-master' element={<PartyMaster />} />
            <Route path='/transport-master' element={<TransportMaster />} />
            <Route path='/brand-master' element={<BrandMaster />} />
            <Route path='/unit-master' element={<UnitMaster />} />
            <Route path='/finish-master' element={<FinishMaster />} />
            <Route path='/sub-group-master' element={<SubGroupMaster />} />
            <Route path='/item-group-master' element={<ItemGroupMaster />} />
            <Route path='/item-master' element={<ItemMaster />} />
            <Route path='/tax-master' element={<TaxMaster />} />
            <Route path='/gst-master' element={<GSTMaster />} />
            <Route path='/hsn-master' element={<HSNMaster />} />
            <Route path='/price-list-master' element={<PriceListMaster />} />
            <Route
              path='/company-price-list'
              element={<CompanyPriceListMaster />}
            />

            {/*Transaction Routes*/}
            <Route path='/purchase-voucher' element={<PurchaseVoucher />} />
            <Route path='/bill-cancellation' element={<BillCancellation />} />
            <Route path='/lr-entry' element={<LREntry />} />
            <Route path='/tax-invoice' element={<TaxInvoice />} />

            {/*Display Routes*/}
            <Route path='/purchase-register' element={<PurchaseRegister />} />
            <Route path='/sales-register' element={<SalesRegister />} />

            {/*Reports Routes*/}
            <Route path='/invoice-printing' element={<InvoicePrinting />} />
            <Route path='/stock-summary' element={<StockSummary />} />

            {/*Accounts Routes*/}
            <Route path='/day-book' element={<DayBook />} />
            <Route path='/creditors-list' element={<CreditorsList />} />
            <Route path='/debtors-list' element={<DebtorsList />} />
            <Route path='/ledger' element={<Ledger />} />

            {/*Administrator Routes*/}
            <Route path='/change-password' element={<ChangePassword />} />
            <Route path='/change-period' element={<ChangePeriod />} />
            <Route path='*' element={<PageNotFound />} />
          </Routes>
        </main>
      </div>
    </div>
  );
};

export default DashBoard;
