import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CustomDatePicker = ({ id, selected, onChange, className, label }) => {
  return (
    <div className='flex flex-col items-start  w-full'>
      <label htmlFor={id} className='text-md text-black font-semibold mb-1'>
        {label}
      </label>
      <DatePicker
        id={id}
        selected={selected}
        onChange={onChange}
        className={`w-full px-3 py-1 border mt-[-5px] focus:outline-none ${className}`}
        dateFormat='dd-MM-yyyy'
      />
    </div>
  );
};

export default CustomDatePicker;
