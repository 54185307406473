import React, { useState } from "react";
import {
  FaChevronDown,
  FaChevronUp,
  FaLock,
  FaUserPlus,
  FaUserShield,
  FaSync,
  FaHeadset,
} from "react-icons/fa";

const faqs = [
  {
    question: "How long you have been in business?",
    answer:
      "We are serving the software development services from past 18 years.",
    icon: <FaUserShield />, // Example icon for "purpose" category
  },
  {
    question: "How to purchase the services?",
    answer:
      "To purchase the services of SRS Softwares you can contact on the number provided.",
    icon: <FaUserPlus />, // Icon for account setup
  },
  {
    question: "How can I try your software before buying?",
    answer: "We do provide the free trial and demo services for the clients.",
    icon: <FaLock />, // Icon for security-related question
  },
  {
    question: "What platforms does your software support?",
    answer: "It can be easily used in Phone or Laptops.",
    icon: <FaSync />, // Icon for plan upgrade
  },
];

const FAQPage = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <section className='flex flex-col items-center justify-center  p-4 w-full'>
      <h2 className='text-xl font-bold text-center mb-5'>
        Frequently Asked Questions
      </h2>
      <div className='w-full bg-white shadow-lg rounded-lg overflow-hidden'>
        {faqs.map((faq, index) => (
          <div key={index} className='border-b'>
            <button
              onClick={() => toggleAccordion(index)}
              className='w-full flex justify-between items-center p-4 text-left text-md font-semibold text-gray-800 focus:outline-none'
            >
              <div className='flex items-center'>
                <span className='mr-3 text-blue-500'>{faq.icon}</span>
                {faq.question}
              </div>
              {openIndex === index ? <FaChevronUp /> : <FaChevronDown />}
            </button>
            {openIndex === index && (
              <div className='p-4 text-gray-600 text-sm ml-10'>
                {faq.answer}
              </div>
            )}
          </div>
        ))}
      </div>
    </section>
  );
};

export default FAQPage;
