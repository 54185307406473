import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DynamicTable from "../../Common/NewDataTable";
import Loader from "../../Common/Loader";
import axios from "axios";
import { BASE_URL } from "../../Common/apiURL";
import { useLocation } from "react-router-dom";
import {
  branch,
  dateFormatter,
  formatDate,
  getCurrentPageData,
  user,
} from "../../../utils/capitalize";
import { MdGetApp } from "react-icons/md";
import Table from "../../Common/SearchFilterTable";

const PurchaseRegister = () => {
  // State variables
  const [dynamicFilterValue, setDynamicFilterValue] = useState(""); // Default value for dynamic filter
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [salesData, setSalesData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filterTypes, setFilterTypes] = useState([]);
  const [dynamicValues, setDynamicValues] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(""); // Track selected category
  const [selectedFilterType, setSelectedFilterType] = useState("0"); // Track selected filter type, initial value "0" for All
  const [showLoader, setShowLoader] = useState(true);

  const current = useLocation();
  const currentPage = current.pathname.split("/")[1];
  const targetPage = getCurrentPageData(currentPage);

  const fetchFilterData = async () => {
    try {
      const Username = user[0]?.UserID;
      const Depot = user[0]?.DpCode;
      const FormKey = targetPage?.FormKey;
      const BranchCode = branch[0]?.BranchCode;
      const requestBody = { Username, Depot, FormKey, BranchCode };

      const config = {
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        },
      };

      const response = await axios.post(
        `${BASE_URL}/dashboard/sale-register-category`,
        requestBody,
        config
      );

      if (response.data.success) {
        setCategories(response.data.category);
        const filterWithAll = [{ Value: "All" }, ...response.data.filter];
        setFilterTypes(filterWithAll);

        // Set the selectedCategory to the first category by default
        if (response.data.category.length > 0) {
          setSelectedCategory(response.data.category[0].CtCode);
        }
      } else {
        console.log("Failed to fetch sale register data");
      }
    } catch (err) {
      console.error("Error fetching sale register data:", err);
    }
  };

  useEffect(() => {
    document.title = "CLOUD ERP || Sale Register";
    fetchFilterData();

    const timer = setTimeout(() => {
      setShowLoader(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (selectedFilterType !== "0" && selectedCategory) {
      // Trigger API call when selected category or filter type changes
      fetchSalesData();
    }
  }, [selectedFilterType, selectedCategory]);

  const fetchSalesData = async () => {
    try {
      const Username = user[0]?.UserID;
      const Depot = user[0]?.DpCode;
      const FormKey = targetPage?.FormKey;
      const BranchCode = branch[0]?.BranchCode;
      const SelectedCategory = selectedCategory;
      const SelectedFilter = selectedFilterType;
      const requestBody = {
        Username,
        Depot,
        FormKey,
        BranchCode,
        SelectedCategory,
        SelectedFilter,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        },
      };

      const response = await axios.post(
        `${BASE_URL}/dashboard/sale-register-category-data`,
        requestBody,
        config
      );
      console.log(response.data.data);
      if (response.data.data) {
        let filterWithAll;
        switch (selectedFilterType) {
          case "1":
            filterWithAll = [{ AName: "All" }, ...response.data.data];
            break;
          case "2":
            filterWithAll = [{ PartyName: "All" }, ...response.data.data];
            break;
          case "3":
            filterWithAll = [{ City: "All" }, ...response.data.data];
            break;
          case "4":
            filterWithAll = [{ State: "All" }, ...response.data.data];
            break;
          case "5":
            filterWithAll = [{ GSTRName: "All" }, ...response.data.data];
            break;
          case "6":
            filterWithAll = [{ LName: "All" }, ...response.data.data];
            break;
          default:
            filterWithAll = [{ name: "All" }];
            break;
        }
        setDynamicValues(filterWithAll);
      } else {
        console.log("Failed to fetch sale register data");
      }
    } catch (err) {
      console.error("Error fetching sale register data:", err);
    }
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);

    setSalesData([]);
  };

  const handleFilterTypeChange = (event) => {
    const value = event.target.value;
    setSelectedFilterType(value);
    setDynamicFilterValue(""); // Reset dynamic filter value
    setSalesData([]);
  };

  const handleDynamicFilterValueChange = (event) => {
    setDynamicFilterValue(event.target.value);
    setSalesData([]);
  };

  const handleFindSalesData = async () => {
    console.log("Selected Category:", selectedCategory);
    console.log("Selected Filter Type:", selectedFilterType);
    console.log("Selected Filter Value:", dynamicFilterValue);
    console.log("Start Date:", startDate);
    console.log("End Date:", endDate);
    setShowLoader(true);

    try {
      const Username = user[0]?.UserID;
      const Depot = user[0]?.DpCode;
      const FormKey = targetPage?.FormKey;
      const BranchCode = branch[0]?.BranchCode;
      const SelectedCategory = selectedCategory;
      const SelectedFilter = selectedFilterType;
      const StartDate = formatDate(startDate);
      const EndDate = formatDate(endDate);
      const Value = dynamicFilterValue;
      const requestBody = {
        Username,
        Depot,
        FormKey,
        BranchCode,
        SelectedCategory,
        SelectedFilter,
        StartDate,
        EndDate,
        Value,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        },
      };

      const response = await axios.post(
        `${BASE_URL}/dashboard/sale-register`,
        requestBody,
        config
      );
      if (response.data.data) {
        setSalesData(response.data.data);
      } else {
        alert("Failed to fetch sale register data");
      }
    } catch (err) {
      console.error("Error fetching sale register data:", err);
    } finally {
      setShowLoader(false);
    }
  };

  // Columns configuration for DynamicTable
  const columns = [
    {
      Header: "S No",
      accessor: "id",
      Cell: ({ row }) => (
        <div style={{ textAlign: "center" }}>
          {row.original.isTotalRow ? "" : row.index + 1}
        </div>
      ),
      isInitiallyVisible: true,
    },
    {
      Header: "Doc Date",
      accessor: "DocDate",
      Cell: ({ value }) => (
        <div style={{ textAlign: "center" }}>{dateFormatter(value)}</div>
      ),
      isInitiallyVisible: true,
    },
    {
      Header: "Doc No",
      accessor: "DocNo",
      width: 100,
      align: "center",
      headerAlign: "center",
      Cell: ({ value }) => <div style={{ textAlign: "center" }}>{value}</div>,
    },
    {
      Header: "Party Name",
      accessor: "LName",
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
      isInitiallyVisible: true,
    },
    {
      Header: "Username",
      accessor: "UserName",
      width: 100,
      Cell: ({ value }) => <div style={{ textAlign: "left" }}>{value}</div>,
      isInitiallyVisible: true,
    },
    {
      Header: "Taxable Value",
      accessor: "TaxableValue",
      width: 250,
      Cell: ({ value }) => (
        <div style={{ textAlign: "right" }}>{parseFloat(value).toFixed(2)}</div>
      ),
      isInitiallyVisible: true,
    },

    {
      Header: "Grand Total",
      accessor: "GrandTotal",
      width: 100,
      Cell: ({ value }) => (
        <div style={{ textAlign: "right" }}>{parseFloat(value).toFixed(2)}</div>
      ),
      isInitiallyVisible: true,
    },
    {
      Header: "PDF",
      accessor: "PDFFileName",
      // Cell: ({ row }) => {
      //   const { PDFFileName } = row.values;
      //   return (
      //     <div className='flex items-center'>
      //       {PDFFileName ? (
      //         <MdGetApp
      //           size={25}
      //           onClick={() => handleDownload(row.values)}
      //           style={{ cursor: "pointer" }}
      //         />
      //       ) : null}
      //     </div>
      //   );
      // },
      Cell: (params) => (
        <div className='flex items-center'>
          <MdGetApp
            size={25}
            onClick={
              params.row.values.PDFFileName === ""
                ? null
                : () => handleDownload(params.row.values)
            }
            className={`cursor-pointer text-${
              params.row.values.PDFFileName == "" ? "red" : "blue"
            }-600 ${
              params.row.values.PDFFileName == ""
                ? "red"
                : "hover:text-green-600"
            }  m-2 mt-3`}
          />
        </div>
      ),
      isInitiallyVisible: true,
    },
  ];
  const handleDownload = () => {};
  return (
    <div className='p-3'>
      {showLoader && <Loader />}

      <h2 className='text-sm font-semibold mb-4'>{targetPage?.FormName}</h2>
      <form>
        <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-2 text-sm'>
          {/* Category Dropdown */}
          <div>
            <label className='block font-medium'>Category</label>
            <select
              value={selectedCategory}
              onChange={handleCategoryChange}
              className='w-full p-1 border rounded text-xs'
            >
              {categories.map((category, index) => (
                <option key={index} value={category.CtCode}>
                  {category.CtName}
                </option>
              ))}
            </select>
          </div>

          {/* Filter Type Dropdown */}
          <div>
            <label className='block font-medium'>Filter</label>
            <select
              value={selectedFilterType}
              onChange={handleFilterTypeChange}
              className='w-full p-1 border rounded text-xs'
            >
              {filterTypes.map((type, index) => (
                <option key={index} value={index}>
                  {type.Value}
                </option>
              ))}
            </select>
          </div>

          {/* Dynamic Filter Value Dropdown */}
          <div>
            <label className='block font-medium'>
              {selectedFilterType === "0"
                ? "Select All"
                : `${filterTypes[selectedFilterType]?.Value}`}
            </label>
            <select
              value={dynamicFilterValue}
              onChange={handleDynamicFilterValueChange}
              className='w-full p-1 border rounded text-xs'
              disabled={selectedFilterType === "0"} // Disable if "All" is selected
            >
              {dynamicValues.map((category, index) => {
                // Handle multiple conditions based on dynamicValues fields
                if (category.AName) {
                  return (
                    <option key={index} value={category.AName}>
                      {category.AName}
                    </option>
                  );
                } else if (category.PartyName) {
                  return (
                    <option key={index} value={category.PartyName}>
                      {category.PartyName}
                    </option>
                  );
                } else if (category.City) {
                  return (
                    <option key={index} value={category.City}>
                      {category.City}
                    </option>
                  );
                } else if (category.State) {
                  return (
                    <option key={index} value={category.State}>
                      {category.State}
                    </option>
                  );
                } else if (category.GSTRName) {
                  return (
                    <option key={index} value={category.GSTRName}>
                      {category.GSTRName}
                    </option>
                  );
                } else if (category.LName) {
                  return (
                    <option key={index} value={category.LName}>
                      {category.LName}
                    </option>
                  );
                } else {
                  return null; // No option if none of the conditions match
                }
              })}
            </select>
          </div>

          {/* Date Pickers */}
          <div>
            <label className='block font-medium'>Start Date</label>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              dateFormat='yyyy-MM-dd'
              className='w-full p-1 border rounded text-xs'
            />
          </div>

          <div>
            <label className='block font-medium'>End Date</label>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              dateFormat='yyyy-MM-dd'
              className='w-full p-1 border rounded text-xs'
            />
          </div>

          {/* Submit Button */}
          <div className='w-full flex justify-end'>
            <button
              type='button'
              onClick={handleFindSalesData}
              className='px-4 py-1 bg-green-600 text-white rounded'
            >
              Find
            </button>
          </div>
        </div>
      </form>

      <div className='mt-6'>
        <Table
          columns={columns}
          data={salesData.length > 0 ? salesData : []} // Use filtered data
        />
      </div>
    </div>
  );
};

export default PurchaseRegister;
