import React, { useState } from "react";
import { useForm } from "react-hook-form";

// Sample data for party names
const partyData = {
  "Party A": {
    billing: {
      address: "123 Main St",
      city: "City A",
      pincode: "123456",
      state: "State A",
      gstin: "GST123456",
      mobile: "1234567890",
    },
    delivery: {
      address: "456 Elm St",
      city: "City A",
      pincode: "123456",
      state: "State A",
      gstin: "GST123456",
      mobile: "1234567890",
    },
  },
  "Party B": {
    billing: {
      address: "789 Oak St",
      city: "City B",
      pincode: "654321",
      state: "State B",
      gstin: "GST654321",
      mobile: "0987654321",
    },
    delivery: {
      address: "321 Pine St",
      city: "City B",
      pincode: "654321",
      state: "State B",
      gstin: "GST654321",
      mobile: "0987654321",
    },
  },
  // Add more parties as needed
};

const TaxInvoice = () => {
  const { register, setValue, watch, handleSubmit } = useForm();
  const [selectedParty, setSelectedParty] = useState("");
  const [isDeliveryEditable, setIsDeliveryEditable] = useState(false);

  const handlePartyChange = (event) => {
    const partyName = event.target.value;
    setSelectedParty(partyName);

    if (partyData[partyName]) {
      const { billing, delivery } = partyData[partyName];

      // Set billing information
      setValue("billingPartyName", partyName);
      setValue("billingAddress", billing.address);
      setValue("billingCity", billing.city);
      setValue("billingPincode", billing.pincode);
      setValue("billingState", billing.state);
      setValue("billingGstin", billing.gstin);
      setValue("billingMobile", billing.mobile);

      // Set delivery information
      setValue("deliveryPartyName", partyName);
      setValue("deliveryAddress", delivery.address);
      setValue("deliveryCity", delivery.city);
      setValue("deliveryPincode", delivery.pincode);
      setValue("deliveryState", delivery.state);
      setValue("deliveryGstin", delivery.gstin);
      setValue("deliveryMobile", delivery.mobile);
    } else {
      // Clear fields if no party is selected
      setValue("billingPartyName", "");
      setValue("billingAddress", "");
      setValue("billingCity", "");
      setValue("billingPincode", "");
      setValue("billingState", "");
      setValue("billingGstin", "");
      setValue("billingMobile", "");
      setValue("deliveryPartyName", "");
      setValue("deliveryAddress", "");
      setValue("deliveryCity", "");
      setValue("deliveryPincode", "");
      setValue("deliveryState", "");
      setValue("deliveryGstin", "");
      setValue("deliveryMobile", "");
    }
  };

  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <div className='p-5'>
      <h2 className='text-sm font-semibold mb-4'>Tax Invoice</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* Party Selection */}
        <div className='mb-4 text-sm'>
          <label className='block font-medium'>Select Party Name</label>
          <select
            className='w-full p-1 border rounded text-xs'
            value={selectedParty}
            onChange={handlePartyChange}
          >
            <option value=''>Select Party</option>
            {Object.keys(partyData).map((party) => (
              <option key={party} value={party}>
                {party}
              </option>
            ))}
          </select>
        </div>

        {/* Billing Information */}
        <div className='grid grid-cols-1 md:grid-cols-2 gap-5'>
          <div className='mb-4'>
            <h3 className='font-semibold text-xs mb-2'>Billing Information</h3>
            <div className='grid grid-cols-2 gap-1 text-xs'>
              <input
                type='text'
                placeholder='Party Name'
                className='p-1 border rounded'
                {...register("billingPartyName")}
                readOnly
              />
              <input
                type='text'
                placeholder='Address'
                className='p-1 border rounded'
                {...register("billingAddress")}
                readOnly
              />
              <input
                type='text'
                placeholder='City'
                className='p-1 border rounded'
                {...register("billingCity")}
                readOnly
              />
              <input
                type='text'
                placeholder='Pincode'
                className='p-1 border rounded'
                {...register("billingPincode")}
                readOnly
              />
              <input
                type='text'
                placeholder='State'
                className='p-1 border rounded'
                {...register("billingState")}
                readOnly
              />
              <input
                type='text'
                placeholder='GSTIN'
                className='p-1 border rounded'
                {...register("billingGstin")}
                readOnly
              />
              <input
                type='text'
                placeholder='Mobile No'
                className='p-1 border rounded'
                {...register("billingMobile")}
                readOnly
              />
            </div>
          </div>

          {/* Delivery Information */}
          <div className='mb-4'>
            <div className='flex items-center mb-2'>
              <input
                type='checkbox'
                className='mr-2'
                onChange={(e) => setIsDeliveryEditable(e.target.checked)}
              />
              <label className='font-semibold text-xs'>
                Edit Delivery Information
              </label>
            </div>
            <div className='grid grid-cols-2 gap-1 text-xs'>
              <input
                type='text'
                placeholder='Party Name'
                className='p-1 border rounded'
                {...register("deliveryPartyName")}
                readOnly
              />
              <input
                type='text'
                placeholder='Address'
                className='p-1 border rounded'
                {...register("deliveryAddress")}
                readOnly={!isDeliveryEditable}
              />
              <input
                type='text'
                placeholder='City'
                className='p-1 border rounded'
                {...register("deliveryCity")}
                readOnly={!isDeliveryEditable}
              />
              <input
                type='text'
                placeholder='Pincode'
                className='p-1 border rounded'
                {...register("deliveryPincode")}
                readOnly={!isDeliveryEditable}
              />
              <input
                type='text'
                placeholder='State'
                className='p-1 border rounded'
                {...register("deliveryState")}
                readOnly={!isDeliveryEditable}
              />
              <input
                type='text'
                placeholder='GSTIN'
                className='p-1 border rounded'
                {...register("deliveryGstin")}
                readOnly={!isDeliveryEditable}
              />
              <input
                type='text'
                placeholder='Mobile No'
                className='p-1 border rounded'
                {...register("deliveryMobile")}
                readOnly={!isDeliveryEditable}
              />
            </div>
          </div>
        </div>

        {/* Invoice Details */}
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* Invoice Details */}
          <div className='grid grid-cols-2 md:grid-cols-6 gap-5 mb-4 text-xs'>
            <input
              type='text'
              placeholder='Invoice No'
              className='p-1 border rounded'
              {...register("invoiceNo", { required: true })}
            />
            <input
              type='date'
              className='p-1 border rounded'
              {...register("invoiceDate", { required: true })}
            />
            <select
              className='p-1 border rounded'
              {...register("agentName", { required: true })}
            >
              <option value=''>Select Agent</option>
              <option value='Agent 1'>Agent 1</option>
              <option value='Agent 2'>Agent 2</option>
            </select>
            <input
              type='text'
              placeholder='Destination'
              className='p-1 border rounded'
              {...register("destination", { required: true })}
            />
            <input
              type='text'
              placeholder="Buyer's Order No"
              className='p-1 border rounded'
              {...register("buyersOrderNo", { required: true })}
            />
            <input
              type='date'
              className='p-1 border rounded'
              {...register("dated", { required: true })}
            />
          </div>

          {/* Transport Details */}
          <div className='grid grid-cols-2 md:grid-cols-6 gap-5 text-xs mb-4'>
            <select
              className='p-1 border rounded'
              {...register("transportType", { required: true })}
            >
              <option value=''>Transport Type</option>
              <option value='Private Vehicle'>Private Vehicle</option>
              <option value='Public Transport'>Public Transport</option>
            </select>
            <input
              type='number'
              placeholder='No of Packages'
              className='p-1 border rounded'
              {...register("noOfPackages", { required: true })}
            />
            <input
              type='text'
              placeholder='Private Mark'
              className='p-1 border rounded'
              {...register("privateMark")}
            />
            <input
              type='text'
              placeholder='GRTIN'
              className='p-1 border rounded'
              {...register("grtin", { required: true })}
            />
            <input
              type='number'
              placeholder='Loading Charges'
              className='p-1 border rounded'
              {...register("loadingCharges")}
            />
            <input
              type='number'
              placeholder='Agent Commission'
              className='p-1 border rounded'
              {...register("agentCommission")}
            />
          </div>

          {/* LR Details */}
          <div className='grid grid-cols-2 md:grid-cols-6 gap-5 mb-4 text-xs'>
            <input
              type='text'
              placeholder='LR No'
              className='p-1 border rounded'
              {...register("lrNo", { required: true })}
            />
            <input
              type='date'
              className='p-1 border rounded'
              {...register("lrDate", { required: true })}
            />
            <input
              type='number'
              placeholder='Freight'
              className='p-1 border rounded'
              {...register("freight", { required: true })}
            />
            <select
              className='p-1 border rounded'
              {...register("freightType", { required: true })}
            >
              <option value=''>Freight Type</option>
              <option value='Regular'>Regular</option>
              <option value='Urgent'>Urgent</option>
            </select>
            <input
              type='number'
              placeholder='Weight'
              className='p-1 border rounded'
              {...register("weight", { required: true })}
            />
            <input
              type='text'
              placeholder='E-Way Bill No'
              className='p-1 border rounded'
              {...register("ewayBillNo")}
            />
          </div>

          {/* Additional Comments */}
          <div className=' text-xs'>
            <textarea
              placeholder='Special Comments'
              className='p-1 border rounded w-full'
              rows='3'
              {...register("specialComments")}
            />
          </div>

          <div className='mb-4 text-xs'>
            <input
              type='text'
              placeholder='Reference No'
              className='p-1 border rounded w-full'
              {...register("refNo")}
            />
          </div>

          <button
            type='submit'
            className='mt-4 w-full text-sm bg-blue-500 text-white py-2 px-4 rounded'
          >
            Submit
          </button>
        </form>
      </form>
    </div>
  );
};

export default TaxInvoice;
