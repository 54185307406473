// src/AgentMaster.js
import React, { useState } from "react";
import CompanyPriceListMasterForm from "./CompanyPriceListMasterForm";
import CompanyPriceListMasterLookUp from "./CompanyPriceListMasterLookUp";

const CompanyPriceListMaster = () => {
  const [activeTab, setActiveTab] = useState("Create");

  // Handle tab switching
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  // Handle form submission
  const handleFormSubmit = (data) => {
    // Process form data here
    console.log("Submitted Data:", data);
  };

  return (
    <div className="p-2">
      <div className="mb-2 w-full flex gap-5">
        <button
          className={`px-4 w-1/2 py-2 mr-2 ${
            activeTab === "Create" ? "bg-blue-400 text-white" : "bg-gray-200"
          } rounded`}
          onClick={() => handleTabClick("Create")}
        >
          Create
        </button>
        <button
          className={`px-4 w-1/2 py-2 ${
            activeTab === "Lookup" ? "bg-blue-400 text-white" : "bg-gray-200"
          } rounded`}
          onClick={() => handleTabClick("Lookup")}
        >
          Lookup
        </button>
      </div>
      <div>
        {activeTab === "Create" ? (
          <CompanyPriceListMasterForm onSubmit={handleFormSubmit} />
        ) : (
          <div className="overflow-auto" style={{ maxHeight: "600px" }}>
            <CompanyPriceListMasterLookUp />
          </div>
        )}
      </div>
    </div>
  );
};

export default CompanyPriceListMaster;
