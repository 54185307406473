import React from "react";
import {
  FaChalkboardTeacher,
  FaCheckCircle,
  FaCloud,
  FaCogs,
  FaExchangeAlt,
  FaHeadset,
  FaPlus,
  FaRupeeSign,
  FaShippingFast,
  FaUsers,
  FaUserTie,
} from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";

// Data for pricing plans
const plans = [
  {
    name: "Base",
    des: "Perfect Plan for Your Successful Small Business",
    user: "Single User",
    price: "₹ 10,000 /-",
    features: [],
  },
  {
    name: "Standard",
    des: "Best Plan for Your Successful Growing Business",
    user: "Multi Users",
    price: "₹ 15,000 /-",
    features: [{ icon: <FaUsers />, text: "5 User License" }],
  },
  {
    name: "Silver",
    des: "Perfect Plan for an Established Businesses",
    user: "Single User",
    price: "₹ 25,000 /-",
    features: [{ icon: <FaExchangeAlt />, text: "Delivery Integrations" }],
  },
  {
    name: "Gold",
    des: "Best Made for your Established Businesses",
    user: "Multi Users",
    price: "₹ 40,000 /-",
    features: [
      { icon: <FaUsers />, text: "5 User License" },
      { icon: <FaExchangeAlt />, text: "Delivery Integrations" },
    ],
  },
  {
    name: "Enterprise",
    des: "Tailored for your Established Business Needs",
    user: "Unlimited Users",
    price: "Get Quote Now",
    features: [
      { icon: <FaUsers />, text: "Unlimited Users" },
      { icon: <FaCogs />, text: "Custom Features & Integrations" },
      { icon: <FaUserTie />, text: "Dedicated Account Manager" },
      { icon: <FaHeadset />, text: "Premium Support" },
      { icon: <FaChalkboardTeacher />, text: "Custom Training" },
    ],
    buttonText: "Get Ultimate Plan",
    buttonIcon: <IoIosArrowForward />,
  },
];

// Pricing Plans Component
const PricingPlans = () => {
  return (
    <section id='pricing' className='py-10 px-4  w-full'>
      <div className='mx-auto text-center'>
        <h2 className='text-xl font-bold text-center text-black mb-8'>
          Choose your Pricing Plans
        </h2>
        <div className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-8'>
          {plans.map((plan, index) => (
            <div
              key={index}
              className={`${
                plan.name === "Enterprise" ? "bg-yellow-300" : "bg-white"
              } outline rounded-lg p-2 flex flex-col items-center shadow-2xl`}
            >
              <h3 className='text-2xl font-semibold text-gray-800'>
                {plan.name}
              </h3>
              <p className='text-sm text-gray-600 mt-5'>{plan.des}</p>
              <div className='bg-black text-white text-sm px-4 py-2 rounded-full mt-2 w-full text-center'>
                {plan.user}
              </div>
              <p className='text-lg font-semibold  mt-2'>{plan.price}</p>
              <ul className='mt-4 text-gray-600'>
                {plan.features.map((feature, idx) => (
                  <li key={idx} className='flex items-center mt-2 text-sm'>
                    <span className='mr-2'>{feature.icon}</span>
                    {feature.text}
                  </li>
                ))}
              </ul>
              {/* Display button only for the "Enterprise" plan */}
              {plan.name === "Enterprise" && (
                <button className='mt-6 px-6 py-3 bg-yellow-500 text-white rounded-md hover:bg-yellow-600 flex items-center'>
                  {plan.buttonText} {plan.buttonIcon}
                </button>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className=' mt-5 text-sm text-gray-600'>
        <span className='text-lg font-bold uppercase'>Note :</span>
        <ul className='space-y-1 ml-10'>
          <li className='flex items-center'>
            <FaCheckCircle className='mr-2 text-green-500' />
            18% GST is applicable on all plans
          </li>
          <li className='flex items-center'>
            <FaCloud className='mr-2 text-blue-500' />
            Price is inclusive of cloud services
          </li>
          <li className='flex items-center'>
            <FaChalkboardTeacher className='mr-2 text-purple-500' />
            Implementation and training charges are free for the first time.
          </li>
          <li className='flex items-center'>
            <FaRupeeSign className='mr-2 text-indigo-500' />
            After the first time, training will be charged at Rs 4999/-
          </li>
          <li className='flex items-center'>
            <FaPlus className='mr-2 text-orange-500' />
            Additional brand or franchise will be charged at INR 8000/- per year
          </li>
          <li className='flex items-center'>
            <FaShippingFast className='mr-2 text-red-500' />
            More than five years will be charged at INR 5000/-
          </li>
        </ul>
      </div>
    </section>
  );
};

export default PricingPlans;
