import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { purchaseVoucherFormFields, itemDetailsFields } from "../../../data"; // Ensure correct path
import CustomDatePicker from "../../../../Common/DatePicker";

const PurchaseVoucherForm = ({ onSubmit }) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const [selectedItem, setSelectedItem] = useState("");
  const [addedItems, setAddedItems] = useState([]); // State to store added items

  // Define static data for items
  const itemDataMapping = {
    "Item 1": {
      qty: "10",
      grossWt: "50",
      bagWt: "5",
      netWt: "45",
      recdWt: "48",
      rate: "200",
      unit: "Unit 1",
      amount: "2000", // Calculated amount
      discount: "5",
      amountAfterDiscount: "1900", // Calculated amount after discount
      brand: "Brand 1",
      remark: "Sample remark",
      gstRate: "18", // Default GST Rate
      cgst: "90", // Calculated CGST
      sgst: "90", // Calculated SGST
      igst: "0", // Calculated IGST
      cess: "0",
    },
    "Item 2": {
      qty: "20",
      grossWt: "100",
      bagWt: "10",
      netWt: "90",
      recdWt: "95",
      rate: "150",
      unit: "Unit 2",
      amount: "3000", // Calculated amount
      discount: "10",
      amountAfterDiscount: "2700", // Calculated amount after discount
      brand: "Brand 2",
      remark: "Sample remark 2",
      gstRate: "18", // Default GST Rate
      cgst: "135", // Calculated CGST
      sgst: "135", // Calculated SGST
      igst: "0", // Calculated IGST
      cess: "0",
    },
    // Add more items as needed
  };

  // Render input fields dynamically
  const renderField = (field) => {
    if (!field) return null; // Handle the case where field is undefined

    const { name, label, type, placeholder, options, validation, readOnly } =
      field;

    const commonProps = {
      ...register(name, validation),
      className: "w-full p-1 border rounded",
    };

    if (type === "text" || type === "email") {
      return (
        <div key={name} className='flex flex-col'>
          <label className='block font-medium'>{label}</label>
          <input
            type={type}
            placeholder={placeholder}
            readOnly={readOnly} // Add readOnly here
            {...commonProps}
          />
          {errors[name] && (
            <span className='text-red-500 text-sm'>{errors[name].message}</span>
          )}
        </div>
      );
    } else if (type === "dropdown") {
      return (
        <div key={name} className='flex flex-col'>
          <label className='block font-medium'>{label}</label>
          <select {...commonProps} readOnly={readOnly}>
            <option value=''>Select {label}</option>
            {options.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          {errors[name] && (
            <span className='text-red-500 text-sm'>{errors[name].message}</span>
          )}
        </div>
      );
    } else if (type === "checkbox") {
      return (
        <div key={name} className='flex items-center'>
          <input type='checkbox' {...commonProps} className='mr-2' />
          <label className='block font-medium'>{label}</label>
        </div>
      );
    } else if (type === "date") {
      return (
        <div key={name} className='flex flex-col'>
          <label className='block font-medium'>{label}</label>
          <CustomDatePicker
            {...commonProps}
            selected={new Date()} // Default selected date can be adjusted as needed
            onChange={(date) => {
              setValue(name, date); // Update form state with the selected date
            }}
          />
          {errors[name] && (
            <span className='text-red-500 text-sm'>{errors[name].message}</span>
          )}
        </div>
      );
    }
    return null;
  };

  // Handle form submission
  const handleFormSubmit = (data) => {
    console.log("Form Data:", data);
    console.log("Added Items:", addedItems); // Log added items
    if (onSubmit) {
      onSubmit(data); // Call the parent onSubmit function if provided
    }
    // Reset the form after submission
    reset();
    setAddedItems([]); // Clear added items after submission
  };

  const handleItemChange = (field) => {
    const itemData = itemDataMapping[field]; // Get static data for the selected item

    if (itemData) {
      // Set form values using static data
      Object.entries(itemData).forEach(([key, value]) => {
        // Check if the value is a number
        if (typeof value === "number" || !isNaN(parseFloat(value))) {
          // If the value can be converted to a number, use toFixed
          setValue(key, parseFloat(value).toFixed(2)); // Update form state with the static values
        } else {
          // Otherwise, just set the value directly
          setValue(key, value);
        }
      });
    } else {
      // If no static data, reset the fields
      itemDetailsFields.forEach(({ name }) => {
        setValue(name, ""); // Resetting values
      });
    }
    setSelectedItem(field); // Update selected item
  };

  // Add item to the addedItems state
  const handleAddItem = () => {
    // Get the supplier name from the form data
    const supplierName = watch("supplierName"); // Use watch to get the current value of supplierName

    const newItem = {
      name: selectedItem,
      supplierName, // Add supplier name here
      ...itemDataMapping[selectedItem],
    };

    setAddedItems((prevItems) => [...prevItems, newItem]); // Add the new item
    setSelectedItem(""); // Reset selected item after adding
    reset(); // Optionally reset the form
  };

  // Delete item from the addedItems state
  const handleDeleteItem = (index) => {
    setAddedItems((prevItems) => prevItems.filter((_, i) => i !== index));
  };

  return (
    <div className='bg-gray-100 p-2 rounded-xl shadow-lg'>
      <h2 className='text-sm font-semibold mb-4'>Purchase Voucher</h2>
      <form
        onSubmit={handleSubmit(handleFormSubmit)} // Updated to handleFormSubmit
        className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 text-sm'
      >
        {purchaseVoucherFormFields.map((field) => renderField(field))}
        <div className='flex flex-col'>
          <label className='block font-medium'>Item Name</label>
          <select
            className='w-full p-1 text-xs border rounded mb-4'
            value={selectedItem}
            onChange={(e) => handleItemChange(e.target.value)}
          >
            <option value=''>Select Item</option>
            {Object.keys(itemDataMapping).map((itemName) => (
              <option key={itemName} value={itemName}>
                {itemName}
              </option>
            ))}
          </select>
        </div>

        {itemDetailsFields.map((field) => renderField(field))}
        <button
          type='button'
          onClick={handleAddItem}
          className='mt-5 p-1 text-xs  bg-green-500 text-white rounded'
          disabled={!selectedItem} // Disable button if no item is selected
        >
          Add Item
        </button>

        {/* Display Added Items in Tabular Format */}
        {addedItems.length > 0 && (
          <div className='mt-4 flex flex-col col-span-full'>
            <table className='min-w-full border-collapse border border-gray-300 text-xs'>
              <thead>
                <tr>
                  <th className='border border-gray-300 p-1'>S No</th>
                  <th className='border border-gray-300 p-1'>Item Name</th>
                  <th className='border border-gray-300 p-1'>Supplier Name</th>
                  <th className='border border-gray-300 p-1'>Quantity</th>
                  <th className='border border-gray-300 p-1'>Actions</th>
                </tr>
              </thead>
              <tbody>
                {addedItems.map((item, index) => (
                  <tr key={index} className='border border-gray-300'>
                    <td className='border border-gray-300 p-1'>{index + 1}</td>
                    <td className='border border-gray-300 p-1'>{item.name}</td>
                    <td className='border border-gray-300 p-1'>
                      {item.supplierName}
                    </td>
                    <td className='border border-gray-300 p-1'>{item.qty}</td>
                    <td className='border border-gray-300 p-1'>
                      <button
                        type='button'
                        onClick={() => handleDeleteItem(index)}
                        className='text-red-500'
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        <div className='flex flex-col col-span-full mt-4'>
          <button
            type='submit'
            className='bg-blue-500 text-xs text-white p-2 rounded hover:bg-blue-600'
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default PurchaseVoucherForm;
