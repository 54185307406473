import React from "react";
import HeroImg from "../../assets/hero.jpg";
import { FaGlobe } from "react-icons/fa";

const Hero = () => {
  return (
    <section
      id='home'
      className='flex flex-col md:flex-row items-center justify-center   relative px-4 w-full gap-2 mt-[50px] p-2'
    >
      {/* Left Side - Content Section */}
      <div className='flex flex-col items-center justify-center text-left w-full md:w-1/2 mt-5'>
        <div className='mb-2'>
          <blockquote className='text-md lg:text-2xl text-black font-semibold uppercase w-full text-center'>
            <span className='text-3xl text-black'>!!!</span> We are changing the
            world with technology{" "}
            <span className='text-3xl text-black'>!!!</span>
          </blockquote>

          <blockquote className='text-sm lg:text-md font-semibold text-gray-700 mt-4 text-justify'>
            Emphasizing the importance of execution in the tech industry, from
            concept to product.Technology is transforming every aspect of our
            lives, from how we communicate to how we work, learn and even solve
            global challenges.
          </blockquote>

          <blockquote className='text-sm lg:text-md font-semibold text-gray-700 mt-1 text-justify'>
            Technology is transforming every aspect of our lives, from how we
            communicate to how we work, learn, and even solve global
            challenges.As we continue to push the boundaries of what’s possible,
            technology has the potential to create a more efficient, equitable,
            and sustainable world for future generations.
          </blockquote>
        </div>
        <button className='mt-2 px-6 py-3 bg-black text-white rounded-md  text-xs w-full'>
          Request a Demo
        </button>
      </div>

      {/* Right Side - Image Section */}
      <div className='w-full md:w-1/2 flex justify-center items-center p-2 md:mt-5'>
        <img
          src={HeroImg}
          alt='Hero Image'
          className='h-full w-full object-cover rounded-xl'
        />
      </div>
    </section>
  );
};

export default Hero;
