import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Check if token exists in localStorage
    const token = localStorage.getItem("token");
    const User = JSON.parse(localStorage.getItem("userInfo")) || [];

    // If token is not found, redirect to login
    if (!token && !User) {
      navigate("/");
    }
  }, []);

  return (
    <div className='flex justify-center items-center h-screen text-xs'>
      <div className='text-center'>
        <h1 className=' text-lg font-bold'>404 - Page Not Found</h1>
        <p className=' mt-4'>The page you are looking for does not exist.</p>
        <button
          onClick={() => navigate("/dashboard")}
          className='mt-6 px-4 py-2 bg-blue-500 text-white rounded-lg'
        >
          Go Back to Dashboard
        </button>
      </div>
    </div>
  );
};

export default PageNotFound;

// nginx

// worker_processes 1;

// events {
//     worker_connections 1024;
// }

// http {
//     include       mime.types;
//     default_type  application/octet-stream;
//     sendfile        on;
//     keepalive_timeout  65;

//     server {
//         listen 0.0.0.0:80;
//         server_name ec2-13-203-20-156.ap-south-1.compute.amazonaws.com;

//         location / {
//             proxy_pass http://localhost:8000;
//             proxy_set_header Host $host;
//             proxy_set_header X-Real-IP $remote_addr;
//             proxy_set_header X-Forwarded-For $proxy_add_x_forwarded_for;
//             proxy_set_header X-Forwarded-Proto $scheme;
//         }

// 	location /website {
//             rewrite ^/website(/.*)$ $1 break;
//             proxy_pass http://localhost:8001;
//             proxy_set_header Host $host;
//             proxy_set_header X-Real-IP $remote_addr;
//             proxy_set_header X-Forwarded-For $proxy_add_x_forwarded_for;
//             proxy_set_header X-Forwarded-Proto $scheme;
//         }

//     }
// }
