import React, { useState } from "react";
import { useForm } from "react-hook-form";

const ChangePeriod = ({ onSubmit }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm();
  const [fromYear, setFromYear] = useState(""); // Track selected From Year

  // Generate a list of years from 2022 to the current year
  const generateYears = (startYear = 2022) => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = startYear; i <= currentYear; i++) {
      years.push(i);
    }
    return years;
  };

  const yearOptions = generateYears(2022); // From 2022 to current year

  // Handle form submission
  const handleFormSubmit = (data) => {
    const { fromYear, toYear } = data;
    if (parseInt(toYear) !== parseInt(fromYear) + 1) {
      setError("toYear", {
        type: "manual",
        message: "'To Year' must be exactly 1 year greater than 'From Year'",
      });
      return;
    }

    console.log("Form Data:", data);
    if (onSubmit) {
      onSubmit(data); // Call parent onSubmit function if provided
    }
    // Display a success alert
    alert("Financial Year changed successfully!");
    // Reset the form after submission
    reset();
    clearErrors();
  };

  // Handle "From Year" change
  const handleFromYearChange = (e) => {
    const selectedYear = e.target.value;
    setFromYear(selectedYear);
    clearErrors("toYear"); // Clear errors for 'To Year' when 'From Year' changes
  };

  return (
    <div className=' p-5'>
      <h2 className='text-sm font-semibold mb-4'>Change Financial Year</h2>
      <form
        onSubmit={handleSubmit(handleFormSubmit)}
        className='grid grid-cols-2 md:grid-cols-2 gap-4'
      >
        {/* From Year */}
        <div className='flex flex-col'>
          <label className='block font-medium text-xs'>From Year</label>
          <select
            {...register("fromYear", {
              required: "From Year is required",
            })}
            className='w-full p-1 border rounded text-xs'
            onChange={handleFromYearChange} // Track the selected From Year
          >
            <option value=''>Select From Year</option>
            {yearOptions.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
          {errors.fromYear && (
            <span className='text-red-500 text-sm'>
              {errors.fromYear.message}
            </span>
          )}
        </div>

        {/* To Year */}
        <div className='flex flex-col'>
          <label className='block font-medium text-xs'>To Year</label>
          <select
            {...register("toYear", {
              required: "To Year is required",
            })}
            className='w-full p-1 border rounded text-xs'
            disabled={!fromYear} // Disable the dropdown if From Year isn't selected
          >
            <option value=''>Select To Year</option>
            {fromYear && (
              <option value={parseInt(fromYear) + 1}>
                {parseInt(fromYear) + 1}
              </option>
            )}
          </select>
          {errors.toYear && (
            <span className='text-red-500 text-sm'>
              {errors.toYear.message}
            </span>
          )}
        </div>

        {/* Submit Button */}
        <div className='col-span-full'>
          <button
            type='submit'
            className='px-4 py-2 bg-blue-600 text-white rounded w-full text-sm'
          >
            Change
          </button>
        </div>
      </form>
    </div>
  );
};

export default ChangePeriod;
