// Exported variables
export const user = JSON.parse(localStorage.getItem("userInfo"));
export const branch = JSON.parse(localStorage.getItem("branches"));
export const menu = JSON.parse(localStorage.getItem("Menu"));

// Function to capitalize title
export function capitalizeTitle(title) {
  if (typeof title !== "string") {
    console.error("capitalizeTitle error: title is not a string", title);
    return title; // Return the original value if it's not a string
  }

  return title
    .split("-") // Split by hyphen
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter
    .join(" "); // Join words with a space
}

// Function to get current page data
export const getCurrentPageData = (currentPage) => {
  const formattedTitle = capitalizeTitle(currentPage);

  // Check if menu exists and is an array
  if (menu && Array.isArray(menu)) {
    return menu.find((item) => item.FormName === formattedTitle);
  }
  return null; // Return null if menu is not available
};

export const formatDate = (date) => {
  const d = new Date(date);
  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, "0");
  const day = String(d.getDate()).padStart(2, "0");
  return `${year}${month}${day}`;
};

export const dateFormatter = (dateString) => {
  if (!dateString) return ""; // Return empty string if dateString is falsy

  const date = new Date(dateString);
  if (isNaN(date.getTime())) return ""; // Return empty string if date is invalid

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};
