import React, { useEffect, useState } from "react";
import {
  FaWallet,
  FaArrowUp,
  FaArrowDown,
  FaClipboardCheck,
  FaMoneyBillAlt,
  FaFileInvoice,
} from "react-icons/fa";
import QuickLinks from "./QuickLinks";
import Loader from "./Common/Loader";
import DoughnutChartComponent from "../charts/PieChartComponent";
import LineChartComponent from "../charts/LineChartComponent";
import BarChartComponent from "../charts/BarChartComponent";
import { getVisitedPages } from "../utils/visitedPages";
import useTrackPageVisits from "../utils/useTrackPageVisits";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import {
  FaHome,
  FaBalanceScale,
  FaUserCheck,
  FaClipboardList,
  FaRegListAlt,
  FaUsers,
  FaCubes,
  FaBuilding,
  FaReceipt,
  FaTags,
  FaListAlt,
  FaBox,
  FaMoneyCheckAlt,
  FaPrint,
  FaLock,
  FaCalendarAlt,
} from "react-icons/fa";
import { FiDollarSign, FiGrid, FiLayers, FiXSquare } from "react-icons/fi";
import { MdInventory2 } from "react-icons/md";

// Dynamic data for statistics

const recentTransactions = [
  { id: 1, name: "Invoice #1234", amount: "₹1200", date: "2024-09-05" },
  { id: 2, name: "Purchase Order #5678", amount: "₹2200", date: "2024-09-03" },
  { id: 3, name: "Payment Received #9101", amount: "₹500", date: "2024-09-02" },
  { id: 4, name: "Invoice #2468", amount: "₹1800", date: "2024-08-30" },
];

const Home = ({ data }) => {
  useTrackPageVisits(); // Track route changes
  const [showLoader, setShowLoader] = useState(true);
  const [filter, setFilter] = useState("");

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const filteredTransactions = recentTransactions.filter((transaction) =>
    transaction.name.toLowerCase().includes(filter.toLowerCase())
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoader(false);
    }, 1500);
    return () => clearTimeout(timer);
  }, []);

  const navigate = useNavigate();

  const visitedPages = JSON.parse(localStorage.getItem("visitedPages")) || [];

  const handleNavigate = (path) => {
    navigate(path);
  };

  const formatRouteName = (name) => {
    if (!name) {
      return "Testing"; // Return empty string if path is undefined or null
    }

    // Remove leading slashes and split by '/'
    const parts = name.replace(/^\//, "").split("/");

    // Capitalize the first letter of each word
    const formattedParts = parts.map(
      (part) =>
        part
          .replace(/-/g, " ") // Replace hyphens with spaces
          .replace(/\b\w/g, (char) => char.toUpperCase()) // Capitalize each word
    );

    // Join the parts with a space and return the formatted name
    return formattedParts.join(" ");
  };

  const iconMapping = {
    103: <FiLayers color='#FF5733' />, // Group Master - Orange
    104: <FaUserCheck color='#33FF57' />, // Ledger Master - Green
    119: <FaUserCheck color='#3357FF' />, // Agent Master - Blue
    120: <FaBalanceScale color='#FF33A1' />, // Transport Master - Pink
    105: <FaUsers color='#33FFF5' />, // Party Master - Aqua
    106: <FaBox color='#FF8C33' />, // Product Master - Light Orange
    107: <FaTags color='#FFC300' />, // Brand Master - Yellow
    108: <FaListAlt color='#DAF7A6' />, // Unit Master - Light Green
    116: <FaClipboardList color='#C70039' />, // Finish Master - Red
    109: <FaCubes color='#900C3F' />, // Item Group Master - Dark Red
    115: <FiGrid color='#581845' />, // Sub Group Master - Purple
    110: <FaBox color='#2ECC71' />, // Item Master - Green
    111: <FaFileInvoice color='#3498DB' />, // GST Setup - Light Blue
    112: <FaMoneyCheckAlt color='#1ABC9C' />, // Tax Master - Teal
    113: <FaReceipt color='#9B59B6' />, // GST Master - Violet
    114: <FaListAlt color='#E74C3C' />, // HSN Master - Orange Red
    117: <FiDollarSign color='#F39C12' />, // Price List Setup - Dark Yellow
    118: <FiDollarSign color='#D35400' />, // Price List Master - Dark Orange
    121: <FaBuilding color='#2980B9' />, // Company Price List - Navy Blue
    202: <FiXSquare color='#8E44AD' />, // Purchase Voucher - Purple
    205: <FaRegListAlt color='#F1C40F' />, // Sales - Gold
    206: <FaFileInvoice color='#27AE60' />, // Tax Invoice - Green
    211: <FaClipboardList color='#2980B9' />, // Billing Information Updation - Blue
    210: <FaBalanceScale color='#16A085' />, // LR Entry - Teal
    213: <FiXSquare color='#C0392B' />, // Bill Cancellation - Dark Red
    303: <FaListAlt color='#7D3C98' />, // Purchase Register - Purple
    302: <FaListAlt color='#E74C3C' />, // Sales Register - Red
    401: <FaPrint color='#34495E' />, // Sales Document Printing - Gray Blue
    402: <FaPrint color='#BDC3C7' />, // Invoice Printing - Light Gray
    403: <MdInventory2 color='#7F8C8D' />, // Inventory - Gray
    404: <MdInventory2 color='#95A5A6' />, // Stock Summary - Light Gray
    1102: <FaLock color='#2C3E50' />, // Change Password - Dark Gray
    1103: <FaCalendarAlt color='#8E44AD' />, // Change Period - Purple
  };

  const statsData = [
    {
      value: `₹ ${data?.PurchaseData?.[0]?.TaxableValue || 0}`,
      label: "Total Purchase",
      totalRecord: data?.PurchaseData?.[0]?.TotRecord || 0,
      icon: FaArrowUp,
      bgColor: "bg-blue-300",
      iconColor: "text-blue-600",
      textColor: "text-blue-800",
    },
    {
      value: `₹ ${data?.SalesData?.[0]?.TaxableValue || 0}`,
      totalRecord: data?.SalesData?.[0]?.TotRecord || 0,
      label: "Total Sales",
      icon: FaArrowUp,
      bgColor: "bg-green-300",
      iconColor: "text-green-600",
      textColor: "text-green-800",
    },
    {
      value: `₹ ${data?.PaymentData?.[0]?.Amount || 0}`,
      totalRecord: data?.PaymentData?.[0]?.TotRecord || 0,
      label: "Total Payment",
      icon: FaMoneyBillAlt,
      bgColor: "bg-red-300",
      iconColor: "text-red-600",
      textColor: "text-red-800",
    },
    {
      value: `₹ ${data?.ReceiptData?.[0]?.Amount || 0}`,
      totalRecord: data?.ReceiptData?.[0]?.TotRecord || 0,
      label: "Total Receipt",
      icon: FaWallet,
      bgColor: "bg-yellow-300",
      iconColor: "text-yellow-600",
      textColor: "text-yellow-800",
    },
  ];

  return (
    <>
      {showLoader && <Loader />}

      <div className='block md:flex p-2 gap-2 cursor-pointer '>
        <div className='grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-1 w-full mb-5 md:mb-0 h-[420px]'>
          <div className='grid grid-cols-2 md:grid-cols-1 lg:grid-cols-2 col-span-1 gap-2 p-2'>
            {statsData.map((stat, index) => {
              const {
                value,
                label,
                totalRecord,
                icon: Icon,
                bgColor,
                iconColor,
              } = stat;
              return (
                <div
                  key={index}
                  className={` p-1 rounded-lg shadow-lg flex items-center ${bgColor} hover:scale-95  duration-700`}
                >
                  <div
                    className={`w-12 h-12 ₹{bgColor} flex items-center justify-center rounded-full`}
                  >
                    <Icon className={`${iconColor} text-2xl`} />
                  </div>
                  <div>
                    <h5 className={`text-sm font-semibold ml-2 ₹{textColor}`}>
                      {value} ({totalRecord})
                    </h5>
                    <h6 className={`text-gray-700 text-xs ml-2`}>{label}</h6>
                  </div>
                </div>
              );
            })}
          </div>

          <div className='bg-white p-4 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300'>
            <h3 className='text-sm font-semibold mb-2'>Sales / Payments</h3>
            <BarChartComponent />
          </div>
        </div>
      </div>

      <div className='grid grid-cols-1 lg:grid-cols-2 w-full gap-2 p-2 '>
        <div className=' p-2 rounded-xl mt-1 overflow-y-auto shadow-2xl'>
          <h3 className='text-sm font-semibold mb-2'>Recently Visited Pages</h3>
          <ul className='divide-y divide-gray-200 text-xs '>
            {visitedPages
              // .filter((page) => page.name !== "/dashboard" || "/" || "/login") // Exclude dashboard route
              .filter(
                (page) => !["/", "/login", "/dashboard"].includes(page.name) // Exclude specific routes
              )
              .map((page, index) => {
                // Format the route name
                // const displayName = formatRouteName(page.name);
                const displayName =
                  page.name === "/dashboard"
                    ? "Homepage"
                    : formatRouteName(page.name);
                return (
                  <li
                    key={index}
                    className='flex p-1 justify-between py-2 text-xs hover:bg-black hover:text-white transition-colors duration-300 cursor-pointer rounded-lg shadow-2xl mb-1 bg-white'
                    onClick={() => handleNavigate(page.name)}
                  >
                    <span>{displayName}</span>
                    <span className='text-gray-400 text-xs'>
                      {new Date(page.timestamp).toLocaleString()}
                    </span>
                  </li>
                );
              })}
          </ul>
        </div>

        <div className='ml-1 mt-1 h-[400px] overflow-y-auto'>
          <QuickLinks />
        </div>
      </div>
    </>
  );
};

export default Home;
