import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/PTACLogo.jpg";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className='bg-white  fixed top-0 left-0 w-full z-10 shadow-2xl'>
      <div className='container mx-auto flex justify-between items-center'>
        {/* Logo */}
        <Link
          to='/'
          className='text-white font-bold text-2xl hover:text-gray-200'
        >
          <img
            src={Logo}
            alt='Logo'
            className='h-[50px] w-full object-contain md:ml-5'
          />
        </Link>
        {/* Menu Items for Desktop */}
        <div className='hidden md:flex space-x-8'>
          <Link
            to='/login'
            className='text-white text-sm bg-[#000000] px-4 py-1 rounded shadow-2xl mr-5'
          >
            Login
          </Link>
        </div>

        {/* Mobile Menu Button */}
        <div className='md:hidden text-black mr-5 text-xl font-bold'>
          <button onClick={toggleMenu}>{isMenuOpen ? "✖" : "☰"}</button>
        </div>
      </div>

      {/* Mobile Menu Panel */}
      {isMenuOpen && (
        <div className='fixed top-0 right-0 w-full h-1/2 bg-white flex flex-col items-center justify-center space-y-4 z-50'>
          {/* Close Icon at the top-right corner */}
          <div className='absolute top-4 right-4'>
            <button onClick={toggleMenu} className='text-black text-2xl'>
              ✖
            </button>
          </div>

          <Link
            to='/login'
            className='text-black text-lg hover:text-gray-200'
            onClick={toggleMenu}
          >
            Login
          </Link>
          <Link
            to='/request-demo'
            className='text-white bg-black px-4 py-1 rounded hover:bg-gray-100'
            onClick={toggleMenu}
          >
            Request a Demo
          </Link>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
