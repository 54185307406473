import React, { useState } from "react";

const LREntry = () => {
  // State to manage form data
  const [category, setCategory] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [staticData, setStaticData] = useState(null);

  // State for user input fields
  const [lrData, setLrData] = useState({
    lrNumber: "",
    lrDate: "",
    weight: "",
    freight: "",
    package: "",
    privateMark: "",
    ewayBillNumber: "",
    amount: 0,
  });

  // Mock function to fetch static data (use an API in a real app)
  const fetchInvoiceDetails = () => {
    const data = {
      date: "2024-10-18",
      partyName: "XYZ Pvt Ltd",
      mobileNumber: "9876543210",
      agentMobileNumber: "9876543211",
      email: "party@example.com",
      transport: "Private Vehicle",
      amount: 5000,
    };
    setStaticData(data);
  };

  // Handle form submission to fetch static data
  const handleSubmit = (e) => {
    e.preventDefault();
    if (category && invoiceNumber) {
      fetchInvoiceDetails();
    } else {
      alert("Please select a category and enter an invoice number.");
    }
  };

  // Handle input changes for LR fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLrData((prevData) => ({ ...prevData, [name]: value }));
  };

  // Handle save button click
  const handleSave = () => {
    if (staticData && lrData.lrNumber && lrData.lrDate) {
      console.log("Saved Data:", { ...staticData, ...lrData });
    } else {
      alert("Please fill in all required fields.");
    }
  };

  return (
    <div className='p-5'>
      <h2 className='text-sm font-bold mb-4'>LR Entry</h2>

      {/* Form to select Category and Invoice Number */}
      <form onSubmit={handleSubmit} className='mb-4 '>
        <div className='grid grid-cols-2 md:grid-cols-3 gap-5 text-sm'>
          <div className='mb-4'>
            <label className='block text-sm font-medium mb-1'>Category</label>
            <select
              className='w-full border p-1 rounded-lg text-xs'
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            >
              <option value=''>Select Category</option>
              <option value='Tax Invoice'>Tax Invoice</option>
              <option value='Credit Note Against Sale'>
                Credit Note Against Sale
              </option>
            </select>
          </div>
          <div className='mb-4'>
            <label className='block text-sm font-medium mb-1'>
              Invoice Number
            </label>
            <input
              type='text'
              className='w-full border p-1 rounded-lg text-xs'
              value={invoiceNumber}
              onChange={(e) => setInvoiceNumber(e.target.value)}
              placeholder='Enter Invoice Number'
            />
          </div>
          <button
            type='submit'
            className='w-full mb-5 mt-5 bg-blue-500 text-white py-2 rounded-lg'
          >
            Fetch Data
          </button>
        </div>
      </form>

      {/* Static Data Fields */}
      {staticData && (
        <div className='bg-gray-300 p-2 rounded-lg border mb-4 text-xs grid grid-cols-2 md:grid-cols-4 gap-2'>
          <p className='text-xs'>
            <span className='font-semibold text-xs'>Date: </span>
            {staticData.date}
          </p>
          <p className='text-xs'>
            <span className='font-semibold text-xs'>Party Name: </span>
            {staticData.partyName}
          </p>
          <p className='text-xs'>
            <span className='font-semibold text-xs'>Mobile Number: </span>
            {staticData.mobileNumber}
          </p>
          <p className='text-xs'>
            <span className='font-semibold text-xs'>Agent Mobile Number: </span>
            {staticData.agentMobileNumber}
          </p>
          <p className='text-xs'>
            <span className='font-semibold text-xs'>Email: </span>
            {staticData.email}
          </p>
          <p className='text-xs'>
            <span className='font-semibold text-xs'>Transport: </span>
            {staticData.transport}
          </p>
        </div>
      )}

      {/* Input Fields for LR Entry */}
      {staticData && (
        <div className='grid grid-cols-2 md:grid-cols-4 gap-5'>
          <div className=''>
            <label className='block text-sm font-medium mb-1'>LR No</label>
            <input
              type='text'
              name='lrNumber'
              className='w-full border p-1 text-xs rounded-lg'
              value={lrData.lrNumber}
              onChange={handleInputChange}
              placeholder='Enter LR No'
            />
          </div>

          <div className=''>
            <label className='block text-sm font-medium mb-1'>LR Date</label>
            <input
              type='date'
              name='lrDate'
              className='w-full border p-1 text-xs rounded-lg'
              value={lrData.lrDate}
              onChange={handleInputChange}
            />
          </div>

          <div className=''>
            <label className='block text-sm font-medium mb-1'>Weight</label>
            <input
              type='number'
              name='weight'
              className='w-full border p-1 text-xs rounded-lg'
              value={lrData.weight}
              onChange={handleInputChange}
              placeholder='Enter Weight'
            />
          </div>

          <div className=''>
            <label className='block text-sm font-medium mb-1'>Freight</label>
            <input
              type='number'
              name='freight'
              className='w-full border p-1 text-xs rounded-lg'
              value={lrData.freight}
              onChange={handleInputChange}
              placeholder='Enter Freight'
            />
          </div>

          <div className=''>
            <label className='block text-sm font-medium mb-1'>Package</label>
            <input
              type='text'
              name='package'
              className='w-full border p-1 text-xs rounded-lg'
              value={lrData.package}
              onChange={handleInputChange}
              placeholder='Enter Package'
            />
          </div>

          <div className=''>
            <label className='block text-sm font-medium mb-1'>Pvt Mark</label>
            <input
              type='text'
              name='privateMark'
              className='w-full border p-1 text-xs rounded-lg'
              value={lrData.privateMark}
              onChange={handleInputChange}
              placeholder='Enter Pvt Mark'
            />
          </div>

          <div className=''>
            <label className='block text-sm font-medium mb-1'>
              Eway Bill No
            </label>
            <input
              type='text'
              name='ewayBillNumber'
              className='w-full border p-1 text-xs rounded-lg'
              value={lrData.ewayBillNumber}
              onChange={handleInputChange}
              placeholder='Enter Eway Bill No'
            />
          </div>
          <div className=''>
            <label className='block text-sm font-medium mb-1'>Amount</label>
            <input
              type='text'
              name='amount'
              className='w-full border p-1 text-xs rounded-lg'
              value={lrData.amount}
              placeholder='Enter Eway Bill No'
            />
          </div>
        </div>
      )}
      <div className=' mt-5 flex w-full'>
        <button
          onClick={handleSave}
          className=' bg-green-500 w-full text-white py-2 px-4 rounded-lg'
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default LREntry;
