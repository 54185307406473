import React from "react";
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";

const ContactPage = () => {
  return (
    <section className='py-5 px-4 w-full'>
      <div className='text-center mb-8'>
        <h2 className='text-xl font-bold text-gray-800'>Contact Us</h2>
        <p className='text-gray-600'>
          We'd love to hear from you! Reach out to us through any of the methods
          below.
        </p>
      </div>

      {/* Contact Information */}
      <div className='grid grid-cols-1 md:grid-cols-3 gap-8 text-center mb-10'>
        <div className='flex flex-col items-center'>
          <FaMapMarkerAlt className='text-4xl text-blue-500 mb-2' />
          <h3 className='text-md font-semibold text-gray-800'>Our Address</h3>
          <p className='text-gray-600 text-xs'>
            C-815, Tower C, 8th Floor, iThum Tower, Near Electronic City Metro,
            Sector 62 Noida, Uttar Pradesh - 201301
          </p>
        </div>

        <div className='flex flex-col items-center '>
          <FaPhoneAlt className='text-4xl text-green-500 mb-2' />
          <h3 className='text-md font-semibold text-gray-800'>Call Us</h3>
          <p className='text-gray-600 text-xs'>+91 90456 04848</p>
        </div>

        <div className='flex flex-col items-center'>
          <FaEnvelope className='text-4xl text-red-500 mb-2' />
          <h3 className='text-md font-semibold text-gray-800'>Email Us</h3>
          <p className='text-gray-600 text-xs'>info@srssoftwares.com</p>
        </div>
      </div>

      <div className='grid grid-cols-1 lg:grid-cols-2 gap-5'>
        {/* Left Side: Contact Form */}
        <div className='flex flex-col items-center'>
          <form className='w-full  bg-white shadow-md rounded px-8 pt-2 pb-2 mb-4'>
            <div className='mb-2'>
              <label
                className='block text-gray-700 text-sm font-bold mb-2'
                htmlFor='name'
              >
                Name
              </label>
              <input
                className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 text-xs focus:outline-none focus:shadow-outline'
                id='name'
                type='text'
                placeholder='Your Name'
                required
              />
            </div>
            <div className='mb-2'>
              <label
                className='block text-gray-700 text-sm font-bold mb-2'
                htmlFor='email'
              >
                Email
              </label>
              <input
                className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 text-xs focus:outline-none focus:shadow-outline'
                id='email'
                type='email'
                placeholder='Your Email'
                required
              />
            </div>
            <div className='mb-2'>
              <label
                className='block text-gray-700 text-sm font-bold mb-2'
                htmlFor='message'
              >
                Message
              </label>
              <textarea
                className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 text-xs focus:outline-none focus:shadow-outline '
                id='message'
                rows='4'
                placeholder='Your Message'
                required
              ></textarea>
            </div>
            <button
              type='submit'
              className='bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded w-full text-xs'
            >
              Send Message
            </button>
          </form>
        </div>

        {/* Right Side: Google Map */}
        <div className='flex justify-center items-center'>
          {/* Replace with your location's embed code from Google Maps */}
          <iframe
            className='w-full h-72 rounded-lg shadow-lg'
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d226208.41899890616!2d77.37504909999999!3d28.585055200000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce41f4ff6b0ab%3A0x1b6a9fc29a3d9d47!2sIThum%20Tower!5e0!3m2!1sen!2sin!4v1635943473785!5m2!1sen!2sin'
            title='Company Location'
            allowFullScreen=''
            loading='lazy'
          ></iframe>
        </div>
      </div>
    </section>
  );
};

export default ContactPage;
