// src/Pages/SubPages/BarChartComponent.jsx
import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import axios from "axios";
import { BASE_URL } from "../Pages/Common/apiURL";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarChartComponent = () => {
  const [dashboardData, setDashboardData] = useState(null);
  const token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("userInfo"));

  const fetchDashboardData = async () => {
    try {
      const UserId = user[0]?.UserID;
      const Depo = user[0]?.DpCode;
      const requestBody = { UserId, Depo };
      const config = {
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
      };
      const response = await axios.post(
        `${BASE_URL}/dashboard/data`,
        requestBody,
        config
      ); // Adjust URL if necessary
      if (response.data.success) {
        setDashboardData(response.data.data);
      } else {
        console.log("Failed to fetch dashboard data");
      }
    } catch (err) {
      console.error("Error fetching dashboard data:", err);
      console.log("An error occurred while fetching dashboard data");
    } finally {
    }
  };
  useEffect(() => {
    fetchDashboardData();
  }, []);

  const salesData = dashboardData?.PurchaseSaleGraph || [];
  const paymentsData = dashboardData?.ReceiptPaymentGraph || [];

  const labels = salesData.map((item) => {
    // Format the EMonth to a more readable format (e.g., 'Aug 2024')
    const month = item.EMonth.substring(0, 2);
    const year = item.EMonth.substring(2, 6);
    return `${new Date(0, month - 1).toLocaleString("default", {
      month: "short",
    })} ${year}`;
  });

  const salesValues = salesData.map((item) => item.TaxableValue);
  const paymentValues = paymentsData.map((item) => item.Amount);

  const data = {
    labels,
    datasets: [
      {
        label: "Sales (Taxable Value)",
        data: salesValues,
        backgroundColor: "rgba(54, 162, 235, 0.5)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
      },
      {
        label: "Payments (Amount)",
        data: paymentValues,
        backgroundColor: "rgba(255, 99, 132, 0.5)", // Different color for payments
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || "";
            if (label) {
              label += ": ";
            }
            label += context.parsed.y; // Show the value on the tooltip
            return label;
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return <Bar data={data} options={options} />;
};

export default BarChartComponent;
