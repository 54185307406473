import React, { useState, useEffect } from "react";
import { useSpring, animated } from "react-spring";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import Logo from "../assets/PTACLogo.jpg";
import Loader from "./Common/Loader";
import { BASE_URL } from "./Common/apiURL";
import Navbar from "./LandingPage/Navbar";
import Footer from "./LandingPage/Footer";

const LoginPageUpdated = () => {
  const [step, setStep] = useState(1);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [errors, setErrors] = useState({});
  const [loginError, setLoginError] = useState("");
  const [response, setResponse] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const fadeIn = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 200,
  });

  const handleLoginClick = async () => {
    const newErrors = {};
    if (step === 1) {
      if (!username) newErrors.username = "Username is required";
      if (!password) newErrors.password = "Password is required";
      if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
      } else {
        setLoading(true);
        const credentials = {
          username: username.toLocaleLowerCase(),
          password,
        };

        try {
          const response = await axios.post(
            `${BASE_URL}/auth/login`,
            credentials
          );
          console.log(response.data);
          const userData = response.data.user;
          const token = response.data.token;
          const sidebarMenu = response.data.sidebarData;
          const branches = response.data.branches;

          if (userData) {
            localStorage.setItem("token", token);
            localStorage.setItem("branches", JSON.stringify(branches));
            localStorage.setItem("userInfo", JSON.stringify(userData));
            localStorage.setItem("Menu", JSON.stringify(sidebarMenu));

            if (branches.length > 1) {
              setStep(2); // Move to step 2 (branch selection)
            } else {
              localStorage.setItem("selectedLocation", branches[0].BranchName); // Set default branch
              navigate("/dashboard"); // Navigate to dashboard
            }
          } else {
            setLoginError("Incorrect credentials");
          }
        } catch (error) {
          console.log(error.response.data.message);
          setLoginError(error.response.data.message || "Internal Server Error");
        } finally {
          setLoading(false);
        }
      }
    } else {
      // For branch selection
      if (!selectedLocation) {
        newErrors.selectedLocation = "Branch is required";
        setErrors(newErrors);
      } else {
        setLoading(true);
        try {
          localStorage.setItem("selectedLocation", selectedLocation);
          navigate("/dashboard"); // Navigate to dashboard
        } catch (error) {
          setLoginError("Error during branch selection");
        } finally {
          setLoading(false);
        }
      }
    }
  };

  let userDepot = JSON.parse(localStorage.getItem("branches"));
  // If `userDepot` is not an array, wrap it as an array
  if (!Array.isArray(userDepot)) {
    userDepot = [userDepot];
  }

  setTimeout(() => {
    setLoginError("");
  }, 5000);

  return (
    <>
      <Navbar />
      <div className='min-h-screen flex items-center justify-center bg-white text-xs'>
        {loading && <Loader />}
        <div className='flex flex-col max-w-md w-full bg-white rounded-2xl shadow-2xl p-4'>
          <animated.h1
            style={fadeIn}
            className='text-3xl font-bold text-center mb-8'
          >
            <img
              src={Logo}
              className='h-[50px] w-full object-contain'
              alt='Logo'
            />
          </animated.h1>

          {step === 1 ? (
            <div>
              <div className='mb-4'>
                <label className='block text-black font-bold mb-2'>
                  Username
                </label>
                <input
                  className='w-full px-3 py-2 border rounded-lg'
                  type='text'
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
                {errors.username && (
                  <p className='text-red-500'>{errors.username}</p>
                )}
              </div>

              <div className='mb-2'>
                <label className='block text-black font-bold mb-2'>
                  Password
                </label>
                <input
                  className='w-full px-3 py-2 border rounded-lg'
                  type='password'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {errors.password && (
                  <p className='text-red-500 '>{errors.password}</p>
                )}
              </div>

              {loginError && (
                <p className='text-red-500 text-center mb-2 font-semibold text-xs'>
                  {loginError}
                </p>
              )}
              <button
                onClick={handleLoginClick}
                className='w-full bg-[#000] text-white py-2 rounded-lg'
              >
                Login
              </button>
            </div>
          ) : (
            <div>
              <div className='mb-4'>
                <label className='block text-black font-bold mb-2'>
                  Select Branch
                </label>
                <select
                  className='w-full px-3 py-2 border rounded-lg'
                  value={selectedLocation}
                  onChange={(e) => setSelectedLocation(e.target.value)}
                >
                  <option value='' disabled>
                    Select Branch
                  </option>
                  {userDepot.map((branch, index) => (
                    <option key={index} value={branch.BranchName}>
                      {branch.BranchName}
                    </option>
                  ))}
                </select>
                {errors.selectedLocation && (
                  <p className='text-red-500'>{errors.selectedLocation}</p>
                )}
              </div>

              <button
                onClick={handleLoginClick}
                className='w-full bg-[#3F908A] text-white py-2 rounded-lg'
              >
                Continue to Dashboard
              </button>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default LoginPageUpdated;
