import React from "react";
import { FaRocket, FaUsers, FaTools, FaCog } from "react-icons/fa";
import HeroImg from "../../assets/about.jpg";

const AboutUs = () => {
  return (
    <section
      id='home'
      className='flex flex-col md:flex-row items-center justify-center relative px-4 w-full gap-2 p-2'
    >
      {/* Left Side - Image Section */}
      <div className='w-full md:w-1/2 flex justify-center items-center p-2 md:mt-5'>
        <img
          src={HeroImg}
          alt='Hero Image'
          className='h-full w-full object-cover rounded-xl'
        />
      </div>

      {/* Right Side - Content Section */}
      <div className='flex flex-col items-start justify-center text-left w-full md:w-1/2 p-6'>
        <div className='mb-2'>
          <blockquote className='text-md lg:text-lg text-black font-semibold uppercase w-full text-justify'>
            We’re a leading software company specializing in innovative
            solutions that streamline business operations and drive digital
            transformation for organizations worldwide.
          </blockquote>

          <blockquote className='text-sm lg:text-md font-semibold text-gray-700 mt-3 text-justify'>
            # We pride ourselves on fostering an inclusive, innovative, and
            collaborative work environment.
          </blockquote>

          <blockquote className='text-sm lg:text-md font-semibold text-gray-700 mt-1 text-justify'>
            # Our mission is to empower businesses with scalable, user-friendly
            software.
          </blockquote>

          <blockquote className='text-sm lg:text-md font-semibold text-gray-700 mt-1 text-justify'>
            # To revolutionize industries with intelligent software that
            simplifies complex problems.
          </blockquote>
        </div>

        {/* Key Points with Icons */}
        <div className='mt-4 space-y-4'>
          <div className='flex items-center space-x-2'>
            <FaRocket className='text-blue-600' />
            <span className='text-sm text-gray-700 font-medium'>
              Innovative and cutting-edge solutions
            </span>
          </div>
          <div className='flex items-center space-x-2'>
            <FaUsers className='text-green-600' />
            <span className='text-sm text-gray-700 font-medium'>
              Collaborative and inclusive work culture
            </span>
          </div>
          <div className='flex items-center space-x-2'>
            <FaTools className='text-yellow-600' />
            <span className='text-sm text-gray-700 font-medium'>
              Tailored software to fit business needs
            </span>
          </div>
          <div className='flex items-center space-x-2'>
            <FaCog className='text-red-600' />
            <span className='text-sm text-gray-700 font-medium'>
              Seamless integration and automation
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
