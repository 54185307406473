import React, { useState } from "react";
import { useForm } from "react-hook-form";

const ChangePassword = () => {
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm();
  const [isVerified, setIsVerified] = useState(false); // To track verification status
  const [isNewPasswordEnabled, setIsNewPasswordEnabled] = useState(false); // To enable new password field

  // Mock password verification (you can replace this with actual API logic)
  const verifyPassword = (data) => {
    const { username, password } = data;

    // Example: Assuming username is "admin" and password is "1234"
    if (username === "amansharma" && password === "aman1234") {
      return true;
    } else {
      return false;
    }
  };

  // Handle form submission
  const handleFormSubmit = (data) => {
    const { username, password, newPassword } = data;

    // If not verified, verify username and password
    if (!isVerified) {
      const verified = verifyPassword(data);

      if (verified) {
        setIsVerified(true);
        setIsNewPasswordEnabled(true);
        clearErrors("password"); // Clear any previous errors
        alert("Verification successful! You can now enter a new password.");
      } else {
        setError("password", {
          type: "manual",
          message: "Invalid username or password.",
        });
        return;
      }
    }
    // If verified, change the password
    else {
      if (!newPassword) {
        setError("newPassword", {
          type: "manual",
          message: "New password is required.",
        });
        return;
      }

      // Handle changing the password (replace this with your logic)
      console.log("New Password Changed:", newPassword);
      alert("Password changed successfully!");
      reset();
    }
  };

  return (
    <div className='p-5'>
      <h2 className='text-sm font-semibold mb-4'>Change Password</h2>
      <form
        onSubmit={handleSubmit(handleFormSubmit)}
        className='grid grid-cols-2 md:grid-cols-2 gap-4'
      >
        {/* Username */}
        <div className='flex flex-col'>
          <label className='block font-medium text-sm'>Username</label>
          <input
            type='text'
            placeholder='Enter Username'
            {...register("username", { required: "Username is required" })}
            className='w-full p-1 border rounded text-sm'
          />
          {errors.username && (
            <span className='text-red-500 text-sm'>
              {errors.username.message}
            </span>
          )}
        </div>
        <div className='grid grid-cols-2 md:grid-cols-2 gap-4'>
          {/* Password */}
          <div className='flex flex-col'>
            <label className='block font-medium text-sm'>Password</label>
            <input
              type='password'
              placeholder='Enter Password'
              {...register("password", { required: "Password is required" })}
              className='w-full p-1 border rounded text-sm'
              disabled={isVerified} // Disable after verification
            />
            {errors.password && (
              <span className='text-red-500 text-sm'>
                {errors.password.message}
              </span>
            )}
          </div>

          {/* New Password (Initially Disabled) */}
          {isVerified && (
            <div className='flex flex-col'>
              <label className='block font-medium text-sm'>New Password</label>
              <input
                type='password'
                placeholder='Enter New Password'
                {...register("newPassword")}
                className='w-full p-1 border rounded text-sm'
                disabled={!isNewPasswordEnabled} // Enable only after verification
              />
              {errors.newPassword && (
                <span className='text-red-500 text-sm'>
                  {errors.newPassword.message}
                </span>
              )}
            </div>
          )}
        </div>
        {/* Button: Change depending on verification status */}
        <div className='col-span-full'>
          <button
            type='submit'
            className='px-4 py-2 text-sm bg-blue-600 text-white rounded w-full'
          >
            {isVerified ? "Change Password" : "Verify"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ChangePassword;
