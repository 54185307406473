import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { companyPriceListMasterFormFields } from "../data"; // Ensure correct path

const CompanyPriceListMasterForm = ({ onSubmit }) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  // State to manage dropdown visibility and selected data
  const [isGroupEnabled, setIsGroupEnabled] = useState(false);
  const [showItems, setShowItems] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [priceList, setPriceList] = useState("");
  const [group, setGroup] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);

  // Static data for demonstration
  const staticData = {
    "Group A": [
      { sNo: "1", groupName: "Group A", itemName: "Item A1", price: "$10" },
      { sNo: "2", groupName: "Group A", itemName: "Item A2", price: "$15" },
    ],
    "Group B": [
      { sNo: "1", groupName: "Group B", itemName: "Item B1", price: "$20" },
      { sNo: "2", groupName: "Group B", itemName: "Item B2", price: "$25" },
    ],
    "Group C": [
      { sNo: "1", groupName: "Group C", itemName: "Item C1", price: "$30" },
      { sNo: "2", groupName: "Group C", itemName: "Item C2", price: "$35" },
    ],
  };

  // Handle Price List change
  const handlePriceListChange = (e) => {
    const selectedPriceList = e.target.value;
    setPriceList(selectedPriceList);
    setIsGroupEnabled(selectedPriceList !== "");
    setGroup(""); // Reset group selection
    setShowItems(false); // Hide items when price list changes
    setFilteredItems([]); // Clear filtered items
  };

  // Handle Group change
  const handleGroupChange = (e) => {
    const selectedGroup = e.target.value;
    setGroup(selectedGroup);
    setFilteredItems(staticData[selectedGroup] || []);
    setShowItems(true); // Show items when group changes
  };

  // Handle Show button click
  const handleShowClick = () => {
    if (group) {
      setShowItems(true);
    } else {
      alert("Please select a group before showing items.");
    }
  };

  // Handle row click
  const handleRowClick = (item) => {
    setSelectedItem(item);
    setValue("groupName", item.groupName);
    setValue("itemName", item.itemName);
    setValue("price", item.price);
  };

  // Render input fields dynamically
  const renderField = (field) => {
    if (!field) return null; // Handle the case where field is undefined

    const { name, label, type, placeholder, options, validation } = field;

    if (type === "text" || type === "email") {
      return (
        <div key={name} className="flex flex-col">
          <label className="block font-medium">{label}</label>
          <input
            type={type}
            placeholder={placeholder}
            {...register(name, validation)}
            className="w-full p-1 border rounded"
          />
          {errors[name] && (
            <span className="text-red-500 text-sm">{errors[name].message}</span>
          )}
        </div>
      );
    } else if (type === "dropdown") {
      return (
        <div key={name} className="flex flex-col">
          <label className="block font-medium">{label}</label>
          <select
            {...register(name, validation)}
            className="w-full p-1 border rounded"
            onChange={
              name === "priceList" ? handlePriceListChange : handleGroupChange
            }
          >
            <option value="">Select {label}</option>
            {options.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          {errors[name] && (
            <span className="text-red-500 text-sm">{errors[name].message}</span>
          )}
        </div>
      );
    } else if (type === "checkbox") {
      return (
        <div key={name} className="flex items-center">
          <input
            type="checkbox"
            {...register(name, validation)}
            className="mr-2"
          />
          <label className="block font-medium">{label}</label>
        </div>
      );
    } else if (type === "button") {
      return (
        <div key={name} className="flex items-center">
          <button
            type="button"
            onClick={handleShowClick}
            className="px-4 py-2 bg-blue-600 text-white rounded"
            disabled={!isGroupEnabled || !group}
          >
            {label}
          </button>
        </div>
      );
    }
    return null;
  };

  // Handle form submission
  const handleFormSubmit = (data) => {
    console.log("Form Data:", data);
    if (onSubmit) {
      onSubmit(data); // Call the parent onSubmit function if provided
    }
    // Reset the form after submission
    reset();
    setSelectedItem(null); // Reset selected item on form submission
    setShowItems(false); // Hide items after submission
  };

  return (
    <div className="bg-gray-100 p-2 rounded-xl shadow-lg">
      <h2 className="text-xl font-semibold mb-4">
        Create Company Price List Form
      </h2>
      <form
        onSubmit={handleSubmit(handleFormSubmit)}
        className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4"
      >
        {companyPriceListMasterFormFields.map((field) => renderField(field))}

        {/* Group dropdown */}
        {isGroupEnabled &&
          renderField({
            name: "group",
            label: "Group",
            type: "dropdown",
            options: ["Group A", "Group B", "Group C"], // Adjust as needed
            validation: { required: "Group is required" },
          })}

        {/* Display static items when Show button is clicked */}
        {showItems && (
          <div className="col-span-full mt-4">
            <table className="min-w-full bg-white border border-gray-200">
              <thead>
                <tr>
                  <th className="border px-4 py-2">S No</th>
                  <th className="border px-4 py-2">Group Name</th>
                  <th className="border px-4 py-2">Item Name</th>
                  <th className="border px-4 py-2">Price</th>
                </tr>
              </thead>
              <tbody>
                {filteredItems.map((item) => (
                  <tr
                    key={item.sNo}
                    className="cursor-pointer hover:bg-gray-100"
                    onClick={() => handleRowClick(item)}
                  >
                    <td className="border px-4 py-2">{item.sNo}</td>
                    <td className="border px-4 py-2">{item.groupName}</td>
                    <td className="border px-4 py-2">{item.itemName}</td>
                    <td className="border px-4 py-2">{item.price}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {/* Display selected item data */}
        {selectedItem && (
          <div className="col-span-full mt-4">
            <h3 className="text-lg font-semibold mb-2">Selected Item</h3>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div className="flex flex-col">
                <label className="block font-medium">Group Name</label>
                <input
                  type="text"
                  {...register("groupName")}
                  className="w-full p-1 border rounded"
                  defaultValue={selectedItem?.groupName || ""}
                />
              </div>

              <div className="flex flex-col">
                <label className="block font-medium">Item Name</label>
                <input
                  type="text"
                  {...register("itemName")}
                  className="w-full p-1 border rounded"
                  defaultValue={selectedItem?.itemName || ""}
                />
              </div>

              <div className="flex flex-col ">
                <label className="block font-medium">Price</label>
                <input
                  type="text"
                  {...register("price")}
                  className="w-full p-1 border rounded"
                  defaultValue={selectedItem?.price || ""}
                />
              </div>
            </div>
          </div>
        )}

        <div className="col-span-full mt-4">
          <button
            type="submit"
            className="px-4 py-2 bg-blue-600 text-white rounded w-full"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default CompanyPriceListMasterForm;
