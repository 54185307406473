import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { FaEdit, FaTrash, FaCaretDown } from "react-icons/fa";
import { hsnMasterFormFields, initialData } from "../data"; // Ensure correct path

const HSNMasterLookUp = () => {
  const [data, setData] = useState(initialData);
  const [editId, setEditId] = useState(null); // To track the row being edited
  const [formData, setFormData] = useState({}); // Form data for edit
  const [visibleColumns, setVisibleColumns] = useState({}); // Track visible columns
  const [dropdownOpen, setDropdownOpen] = useState(false); // Track dropdown visibility

  // Initialize visible columns
  useEffect(() => {
    const initialVisible = hsnMasterFormFields.reduce((acc, field) => {
      acc[field.name] = true; // All columns visible by default
      return acc;
    }, {});
    setVisibleColumns({
      "S No": true, // Ensure serial number is always visible
      ...initialVisible,
      Actions: true, // Ensure Actions column is always visible
    });
  }, []);

  // Edit handler
  const handleEdit = (row) => {
    setEditId(row.id); // Set the ID of the row being edited
    setFormData({ ...row }); // Set current row data in form
  };

  // Save the edited row
  const handleSave = () => {
    const updatedData = data.map((row) =>
      row.id === editId ? { ...row, ...formData } : row
    );
    setData(updatedData); // Update data
    setEditId(null); // Exit edit mode
  };

  // Delete handler
  const handleDelete = (rowId) => {
    const updatedData = data.filter((row) => row.id !== rowId);
    setData(updatedData);
  };

  // Handle form data change for editing
  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle column visibility change
  const handleColumnVisibilityChange = (e) => {
    const { name, checked } = e.target;
    setVisibleColumns((prev) => ({ ...prev, [name]: checked }));
  };

  // Dynamically create columns based on form fields and visibility
  const columns = [
    {
      name: "S No",
      selector: (row, index) => index + 1, // Generate serial number dynamically
      sortable: true,
      width: "80px",
      cell: (row, index) => <div className="text-center py-2">{index + 1}</div>,
    },
    {
      name: "Actions",
      cell: (row) =>
        editId === row.id ? (
          <button
            onClick={handleSave}
            className="text-green-500 px-2 py-1 border rounded"
          >
            Save
          </button>
        ) : (
          <>
            <FaEdit
              className="text-blue-500 cursor-pointer mx-2"
              onClick={() => handleEdit(row)}
            />
            <FaTrash
              className="text-red-500 cursor-pointer"
              onClick={() => handleDelete(row.id)}
            />
          </>
        ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      omit: !visibleColumns["Actions"],
    },
    ...hsnMasterFormFields.map((field) => ({
      name: field.label,
      selector: (row) =>
        editId === row.id ? (
          <input
            type={field.type}
            name={field.name}
            value={formData[field.name] || ""}
            onChange={handleInputChange}
            className="border p-1 rounded"
          />
        ) : (
          row[field.name] || "" // Provide a default value to avoid undefined
        ),
      sortable: true,
      omit: !visibleColumns[field.name],
    })),
  ];

  // Render the column visibility dropdown
  const columnOptions = hsnMasterFormFields.map((field) => (
    <div key={field.name} className="flex items-center mb-1">
      <input
        type="checkbox"
        name={field.name}
        checked={visibleColumns[field.name]}
        onChange={handleColumnVisibilityChange}
        className="mr-2"
      />
      <label>{field.label}</label>
    </div>
  ));

  return (
    <div className="p-4 bg-gray-100 rounded-xl shadow-md">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold">Lookup</h2>
        <div className="relative ">
          <button
            onClick={() => setDropdownOpen(!dropdownOpen)}
            className="bg-gray-500 text-white px-4 py-2 rounded flex items-center"
            style={{ zIndex: 20 }} // Adjust z-index
          >
            <FaCaretDown className="mr-2" /> Columns
          </button>
          {dropdownOpen && (
            <div
              className="absolute right-0 mt-2 bg-white border rounded shadow-lg p-2 h-[420px] overflow-y-auto"
              style={{ zIndex: 10 }} // Adjust z-index
            >
              <div className="flex flex-col">
                <div className="flex items-center mb-1">
                  <input
                    type="checkbox"
                    name="S No"
                    checked={visibleColumns["S No"]}
                    onChange={handleColumnVisibilityChange}
                    className="mr-2"
                    readOnly
                  />
                  <label className="text-sm">S No</label>
                </div>
                <label className="text-sm">{columnOptions}</label>
                <div className="flex items-center mb-1">
                  <input
                    type="checkbox"
                    name="Actions"
                    checked={visibleColumns["Actions"]}
                    onChange={handleColumnVisibilityChange}
                    className="mr-2"
                    readOnly
                  />
                  <label>Actions</label>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <DataTable
        columns={columns}
        data={data}
        pagination
        paginationPerPage={10}
        highlightOnHover
        striped
        responsive
        customStyles={{
          rows: {
            style: {
              minHeight: "30px",
              fontSize: "14px",
            },
          },
          headCells: {
            style: {
              paddingLeft: "4px",
              fontSize: "16px",
              backgroundColor: "#bababa",
              color: "black",
              borderBottom: "2px solid #ddd",
              borderRight: "2px solid #ddd",
            },
          },
          cells: {
            style: {
              paddingLeft: "8px",
            },
          },
          table: {
            style: {
              borderRadius: "8px",
              overflow: "hidden",
              border: "1px solid #ddd",
            },
          },
        }}
      />
    </div>
  );
};

export default HSNMasterLookUp;
