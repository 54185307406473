import React, { useMemo, useState } from "react";
import { useTable, useSortBy, usePagination, useFilters } from "react-table";
import { FaArrowLeft, FaArrowRight, FaFilter } from "react-icons/fa"; // Importing icons

const DynamicTable = ({ columns, rows }) => {
  // State to manage column visibility
  const [hiddenColumns, setHiddenColumns] = useState(
    columns.reduce(
      (acc, col) => ({ ...acc, [col.accessor]: !col.isInitiallyVisible }),
      {}
    )
  );
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // Memoized columns with visibility based on hiddenColumns state
  const visibleColumns = useMemo(
    () => columns.filter((col) => !hiddenColumns[col.accessor]),
    [hiddenColumns, columns]
  );

  // Toggle column visibility
  const handleColumnToggle = (accessor) => {
    setHiddenColumns((prev) => ({
      ...prev,
      [accessor]: !prev[accessor],
    }));
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: visibleColumns,
      data: rows,
      initialState: { pageIndex: 0, pageSize: 10 }, // Set pageSize to 10
    },
    useFilters,
    useSortBy,
    usePagination
  );

  return (
    <div className='p-1 mt-6 bg-white shadow-2xl rounded-lg '>
      {/* Dropdown for Column Visibility */}
      <div className='flex justify-end mb-2'>
        <button
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          className='flex items-center bg-gray-200 border rounded-md p-2'
        >
          <FaFilter className='mr-2' />
          <span className='text-xs'> Columns</span>
        </button>
        {isDropdownOpen && (
          <div
            className='absolute right-4 top-60 bg-white shadow-lg border rounded-md p-3 z-10'
            style={{ width: "200px" }}
          >
            {columns.map((col) => (
              <div key={col.accessor} className='flex items-center mb-2'>
                <input
                  type='checkbox'
                  checked={!hiddenColumns[col.accessor]}
                  onChange={() => handleColumnToggle(col.accessor)}
                  className='mr-2 '
                />
                <label className='text-xs'>{col.Header}</label>
              </div>
            ))}
          </div>
        )}
      </div>

      <div className=' h-[500px] rounded-lg  overflow-x-auto m-2'>
        <div className=''>
          <table {...getTableProps()} className='w-full border-[#000]'>
            <thead className='bg-[#fff] text-black text-sm'>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()} className=''>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className='px-3 py-2 border-[#000]'
                    >
                      {column.render("Header")}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody
              {...getTableBodyProps()}
              className='border border-[#000] text-xs'
            >
              {page.map((row, index) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    className={index % 2 === 0 ? "bg-white" : "bg-blue-100"}
                    style={{
                      backgroundColor:
                        row.original?.PDays > 180
                          ? "#FF0000"
                          : row.original?.PDays > 90
                          ? "#FFA500"
                          : row.original?.PDays > 60
                          ? "cyan"
                          : row.original?.LName === "Total"
                          ? "#ceecaa"
                          : null,
                      color:
                        row.original?.PDays > 180 ||
                        row.original?.PDays > 90 ||
                        row.original?.PDays > 60
                          ? "#fff"
                          : "#000",
                      fontWeight:
                        row.original?.LName === "Total" ? "bold" : "normal",
                      fontSize:
                        row.original?.LName === "Total" ? "10px" : "14px",
                    }}
                  >
                    {row.cells.map((cell) => (
                      <td
                        {...cell.getCellProps()}
                        className='px-2 border border-[#000] text-xs'
                      >
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      <div className='bg-[#fff] w-full p-1 flex justify-between rounded-lg'>
        <button
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
          className='bg-gray-700 rounded-md px-2 py-1'
        >
          <FaArrowLeft color='white' />
        </button>
        <span className='text-black text-sm'>
          Page{" "}
          {rows.length > 0 ? (
            <strong>
              {pageIndex + 1} of {Math.ceil(rows.length / pageSize)}
            </strong>
          ) : (
            <strong>
              {0} of {1}
            </strong>
          )}
        </span>
        <button
          onClick={() => nextPage()}
          disabled={!canNextPage}
          className='bg-gray-700 rounded-md px-2 py-1'
        >
          <FaArrowRight color='white' />
        </button>
      </div>
    </div>
  );
};

export default DynamicTable;
