import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { jsPDF } from "jspdf"; // Library to generate PDF
import "jspdf-autotable"; // For tables in PDF

const InvoicePrinting = () => {
  // State variables
  const [selectedParty, setSelectedParty] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [category, setCategory] = useState("Tax Invoice");
  const [selectionMode, setSelectionMode] = useState("auto");
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [filteredInvoices, setFilteredInvoices] = useState([]);

  // Static data for party names and invoices
  const parties = ["Party A", "Party B", "Party C"];
  const invoices = [
    {
      id: 1,
      invoiceNo: "INV001",
      party: "Party A",
      date: new Date(2024, 9, 1),
    },
    {
      id: 2,
      invoiceNo: "INV002",
      party: "Party A",
      date: new Date(2024, 9, 5),
    },
    {
      id: 3,
      invoiceNo: "INV003",
      party: "Party B",
      date: new Date(2024, 9, 3),
    },
    {
      id: 4,
      invoiceNo: "INV004",
      party: "Party C",
      date: new Date(2024, 9, 10),
    },
    {
      id: 5,
      invoiceNo: "INV005",
      party: "Party C",
      date: new Date(2024, 9, 12),
    },
  ];

  // Handle party selection
  const handlePartyChange = (event) => {
    setSelectedParty(event.target.value);
  };

  // Handle category change
  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  // Handle selection mode change
  const handleSelectionModeChange = (event) => {
    setSelectionMode(event.target.value);
    setSelectedInvoices([]); // Reset selection when mode changes
    setSelectAll(false); // Reset select all checkbox
  };

  // Handle individual invoice selection
  const handleInvoiceSelect = (id) => {
    setSelectedInvoices((prev) =>
      prev.includes(id) ? prev.filter((i) => i !== id) : [...prev, id]
    );
  };

  // Handle select all checkbox
  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedInvoices([]); // Deselect all
    } else {
      setSelectedInvoices(filteredInvoices.map((invoice) => invoice.id)); // Select all
    }
    setSelectAll(!selectAll);
  };

  // Function to generate PDF
  const generatePDF = () => {
    const doc = new jsPDF();
    doc.text("Invoice Summary", 20, 20);
    doc.autoTable({
      head: [["Invoice No", "Party", "Category", "Date"]],
      body: selectedInvoices.map((id) => {
        const invoice = filteredInvoices.find((inv) => inv.id === id);
        return [
          invoice.invoiceNo,
          invoice.party,
          category,
          invoice.date.toLocaleDateString(),
        ];
      }),
    });
    doc.save("invoice.pdf");
  };

  // Find button handler
  const handleFindInvoices = () => {
    const foundInvoices = invoices.filter((invoice) => {
      return (
        invoice.party === selectedParty &&
        invoice.date >= startDate &&
        invoice.date <= endDate
      );
    });
    setFilteredInvoices(foundInvoices);
    setSelectedInvoices([]); // Reset selected invoices
    setSelectAll(false); // Reset select all checkbox
  };

  return (
    <div className='p-5'>
      <h2 className='text-sm font-semibold mb-4'>Invoice Printing</h2>
      <form className=''>
        {/* Date Range Selection */}
        <div className='grid grid-cols-3 md:grid-cols-4 gap-2 text-sm'>
          {/* Party Dropdown */}
          <div>
            <label className='block font-medium'>Select Party</label>
            <select
              value={selectedParty}
              onChange={handlePartyChange}
              className='w-full p-2 border rounded text-xs'
            >
              <option value='' disabled>
                Select a Party
              </option>
              {parties.map((party, index) => (
                <option key={index} value={party}>
                  {party}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label className='block font-medium'>From</label>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              className='w-full p-2 border rounded text-xs'
            />
          </div>
          <div>
            <label className='block font-medium'>To</label>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              className='w-full p-2 border rounded text-xs'
            />
          </div>
          {/* Find Button */}
          <div>
            <button
              type='button'
              onClick={handleFindInvoices}
              className='px-4 py-2 bg-green-600 text-white rounded w-full mt-5'
            >
              Find
            </button>
          </div>
        </div>
        <div className='grid grid-cols-2 gap-2 text-sm'>
          {/* Category Dropdown */}
          <div>
            <label className='block font-medium'>Category</label>
            <select
              value={category}
              onChange={handleCategoryChange}
              className='w-full p-2 border rounded text-xs'
            >
              <option value='Tax Invoice'>Tax Invoice</option>
              <option value='Credit Note'>Credit Note</option>
            </select>
          </div>

          {/* Selection Mode */}
          <div className=''>
            <label className='block font-medium'>Selection Mode</label>
            <select
              value={selectionMode}
              onChange={handleSelectionModeChange}
              className='w-full p-2 border rounded text-xs'
            >
              <option value='auto'>Auto</option>
              <option value='manual'>Manual</option>
            </select>
          </div>
          {/* Invoice Selection */}
          {selectionMode === "auto" && (
            <div className='mt-4 bg-gray-200 p-2 rounded-xl mb-2'>
              <input
                type='checkbox'
                checked={selectAll}
                onChange={handleSelectAll}
                className='text-xs'
              />
              <label className='text-xs ml-1'>Select All</label>
              <div className='mt-2'>
                {filteredInvoices.map((invoice) => (
                  <div key={invoice.id}>
                    <input
                      type='checkbox'
                      checked={selectedInvoices.includes(invoice.id)}
                      onChange={() => handleInvoiceSelect(invoice.id)}
                      className='text-xs'
                    />
                    <label className='text-xs ml-1'>{invoice.invoiceNo}</label>
                  </div>
                ))}
              </div>
            </div>
          )}

          {selectionMode === "manual" && (
            <div className='mt-4 bg-gray-200 p-2 rounded-xl mb-2'>
              <input
                type='text'
                placeholder='From Invoice No'
                className='w-full p-1 border rounded mb-2'
              />
              <input
                type='text'
                placeholder='To Invoice No'
                className='w-full p-1 border rounded'
              />
            </div>
          )}
          <div className='mt-10'>
            <button
              type='button'
              onClick={generatePDF}
              className='px-4 py-2 bg-blue-600 text-white rounded w-full'
            >
              Print Invoice
            </button>
          </div>
        </div>

        {/* Print Button */}
      </form>
    </div>
  );
};

export default InvoicePrinting;
