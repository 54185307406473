import React from "react";
import {
  FaRegThumbsUp,
  FaClock,
  FaCogs,
  FaHeadset,
  FaDollarSign,
  FaUsers,
} from "react-icons/fa";

const WhyChooseUs = () => {
  return (
    <section id='why-choose-us' className='py-5 px-4  w-full'>
      <div className=' mx-auto text-center mb-12'>
        <h2 className='text-xl font-bold text-black mb-3'>Why Choose Us?</h2>
        <p className='text-md text-gray-600 text-justify'>
          We are providing faster deployment, custom solutions, superior
          customer support, and more. Our team is skilled in the latest
          technologies and follows agile development practices to ensure
          flexibility, timely delivery, and high-quality results. From initial
          consultation to post-launch support, we’re with you every step of the
          way, offering transparent pricing and ongoing maintenance to ensure
          your software continues to perform as your business grows.
        </p>
      </div>

      <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8'>
        {/* Faster Deployment */}
        <div className='bg-white p-6 rounded-lg shadow-xl hover:shadow-2xl transition duration-300 ease-in-out'>
          <div className='flex justify-center mb-4'>
            <FaClock size={30} className='text-blue-500' />
          </div>
          <h3 className='text-lg font-semibold text-gray-800 mb-2'>
            Faster Deployment
          </h3>
          <p className='text-gray-600 text-sm'>
            We prioritize faster deployment so you can get your product to
            market quickly and efficiently.
          </p>
        </div>

        {/* Custom Solutions */}
        <div className='bg-white p-6 rounded-lg shadow-xl hover:shadow-2xl transition duration-300 ease-in-out'>
          <div className='flex justify-center mb-4'>
            <FaCogs size={30} className='text-green-500' />
          </div>
          <h3 className='text-lg font-semibold text-gray-800 mb-2'>
            Custom Solutions
          </h3>
          <p className='text-gray-600 text-sm'>
            We create tailored solutions that fit your unique business
            requirements, ensuring scalability and flexibility.
          </p>
        </div>

        {/* Superior Customer Support */}
        <div className='bg-white p-6 rounded-lg shadow-xl hover:shadow-2xl transition duration-300 ease-in-out'>
          <div className='flex justify-center mb-4'>
            <FaHeadset size={30} className='text-purple-500' />
          </div>
          <h3 className='text-lg font-semibold text-gray-800 mb-2'>
            Superior Customer Support
          </h3>
          <p className='text-gray-600 text-sm'>
            Our team is available 24/7 to provide you with top-tier support,
            ensuring smooth operations and quick issue resolution.
          </p>
        </div>

        {/* Agile Development */}
        <div className='bg-white p-6 rounded-lg shadow-xl hover:shadow-2xl transition duration-300 ease-in-out'>
          <div className='flex justify-center mb-4'>
            <FaUsers size={30} className='text-yellow-500' />
          </div>
          <h3 className='text-lg font-semibold text-gray-800 mb-2'>
            Agile Development
          </h3>
          <p className='text-gray-600 text-sm'>
            We follow agile practices to ensure flexibility and adaptability,
            enabling us to respond quickly to any changes or new requirements.
          </p>
        </div>

        {/* Transparent Pricing */}
        <div className='bg-white p-6 rounded-lg shadow-xl hover:shadow-2xl transition duration-300 ease-in-out'>
          <div className='flex justify-center mb-4'>
            <FaDollarSign size={30} className='text-green-500' />
          </div>
          <h3 className='text-lg font-semibold text-gray-800 mb-2'>
            Transparent Pricing
          </h3>
          <p className='text-gray-600 text-sm'>
            We provide clear and upfront pricing, so there are no hidden costs.
            You’ll know exactly what you’re paying for.
          </p>
        </div>

        {/* Timely Delivery */}
        <div className='bg-white p-6 rounded-lg shadow-xl hover:shadow-2xl transition duration-300 ease-in-out'>
          <div className='flex justify-center mb-4'>
            <FaClock size={30} className='text-blue-500' />
          </div>
          <h3 className='text-lg font-semibold text-gray-800 mb-2'>
            Timely Delivery
          </h3>
          <p className='text-gray-600 text-sm'>
            Our team ensures on-time delivery, meeting your deadlines while
            maintaining the quality of the product.
          </p>
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;
