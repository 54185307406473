import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Loader from "../../../Common/Loader"; // Ensure you have a Loader component
import { useLocation } from "react-router-dom";
import {
  branch,
  formatDate,
  getCurrentPageData,
  user,
} from "../../../../utils/capitalize";
import axios from "axios";
import { BASE_URL } from "../../../Common/apiURL";
import Table from "../../../Common/SearchFilterTable";

const StockSummary = () => {
  const [itemGroup, setItemGroup] = useState("");
  const [itemName, setItemName] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [showData, setShowData] = useState(false); // To control table display
  const [isLoading, setIsLoading] = useState(false); // Loader state
  const [rows, setRows] = useState([]);
  const [resultWebPartyList, setResultWebPartyList] = useState([]);

  // Basic Information For API's
  const current = useLocation();
  const currentPage = current.pathname.split("/")[1];
  const targetPage = getCurrentPageData(currentPage);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const Username = user[0]?.UserID;
        const Depot = user[0]?.DpCode;
        const FormKey = targetPage?.FormKey;
        const BranchCode = branch[0]?.BranchCode;
        const requestBody = { Username, Depot, FormKey, BranchCode };

        const config = {
          headers: {
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
          },
        };

        const response = await axios.post(
          `${BASE_URL}/dashboard/web-master`,
          requestBody,
          config
        );
        if (response.data.success) {
          const filterWithAll = [{ IGName: "All" }, ...response.data.data];
          setResultWebPartyList(filterWithAll); // Store the result
        } else {
          console.log("Failed to fetch Parties data");
        }
      } catch (err) {
        console.error("Error fetching Parties data:", err);
      }
    };
    fetchData();
  }, []);

  // Handle the show button click
  const handleShowData = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Show loader while fetching data
    setShowData(false); // Hide table initially during loading

    const Username = user[0]?.UserID;
    const Depot = user[0]?.DpCode;
    const FormKey = targetPage?.FormKey;
    const BranchCode = branch[0]?.BranchCode;

    const requestBody = {
      Depot,
      BranchCode,
      Username,
      FormKey,
      PartyName: "",
      AgentName: "",
      ItemGroup: itemGroup !== "All" ? itemGroup : "",
      ItemName: itemName ? itemName : "",
      Date1: formatDate(startDate),
      Date2: formatDate(startDate),
      RptCode: 0,
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
        token: localStorage.getItem("token"),
      },
    };
    try {
      const response = await axios.post(
        `${BASE_URL}/dashboard/web-reports`,
        requestBody,
        config
      );
      setRows(response.data.data); // Set rows with fetched data
      setShowData(true); // Display the table
    } catch (error) {
      alert("Something went wrong!!!", error);
    } finally {
      setIsLoading(false); // Hide loader after fetching
    }
  };

  // Handle filter change
  const handleFilterChange = (event) => {
    setItemGroup(event.target.value);
  };

  const columns = [
    {
      Header: "S No",
      Cell: ({ row }) => (
        <div style={{ textAlign: "center" }}>
          {row.original.isTotalRow ? "" : row.index + 1}
        </div>
      ),
      isInitiallyVisible: true,
    },
    {
      Header: "Item Name",
      accessor: "IName",
      isInitiallyVisible: true,
    },
    {
      accessor: "BalQty",
      Header: "Quantity",
      Cell: ({ value }) => (
        <div style={{ textAlign: "right" }}>
          {typeof value === "number" ? value.toFixed(2) : value}
        </div>
      ),
      isInitiallyVisible: true,
    },
  ];

  const handleItemNameChange = (e) => {
    const inputValue = e.target.value;
    setItemName(inputValue);
  };

  return (
    <div className='p-5'>
      {isLoading && <Loader />} {/* Show loader when loading */}
      <h1 className='text-sm font-semibold mb-4'>{targetPage.FormName}</h1>
      <form className='grid grid-cols-2 md:grid-cols-4 gap-2'>
        {/* Filter Dropdown */}
        <div className='flex flex-col'>
          <label className='block font-medium text-sm'>Item Group </label>
          <select
            value={itemGroup}
            onChange={handleFilterChange}
            className='w-full p-1 text-xs border rounded'
          >
            {resultWebPartyList.map((category, index) => (
              <option key={index} value={category.IGName}>
                {category.IGName}
              </option>
            ))}
          </select>
        </div>

        <div className='flex flex-col'>
          <label className='text-sm font-medium '>Item Name</label>
          <input
            type='text'
            placeholder='Enter Item Name'
            value={itemName} // Controlled input value
            onChange={handleItemNameChange} // Handler for input changes
            className='p-1 border border-gray-300 font-semibold bg-gray-300 text-xs rounded-md focus:outline-none focus:ring-2 focus:ring-black'
          />
        </div>

        {/* Datepicker */}
        <div className='flex flex-col'>
          <label className='block font-medium text-sm'>Date</label>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            className='w-full p-1 border rounded text-xs'
          />
        </div>

        {/* Show Button */}
        <div className='w-full'>
          <button
            type='button'
            onClick={handleShowData}
            className='px-4 py-2 text-xs bg-blue-600 text-white rounded w-full mt-4'
          >
            Show
          </button>
        </div>
      </form>
      <div className='mt-5'>
        <Table columns={columns} data={rows} />
      </div>
    </div>
  );
};

export default StockSummary;
