import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DynamicTable from "../../Common/NewDataTable";
import Loader from "../../Common/Loader"; // Ensure you have a Loader component
import axios from "axios";
import { BASE_URL } from "../../Common/apiURL";
import { useLocation, useNavigate } from "react-router-dom";
import Table from "../../Common/SearchFilterTable";
import {
  branch,
  formatDate,
  getCurrentPageData,
  user,
} from "../../../utils/capitalize";

const groupDataByDocID = (data) => {
  let groupedData = [];
  let currentDocID = null;
  let serialNumber = 0; // Initialize a serial number

  data.forEach((item) => {
    if (item.DocID !== currentDocID) {
      // Reset the serial number for each new DocID
      serialNumber = 1; // Start numbering from 1 for new group
      groupedData.push({
        isGroupHeader: true,
        DocID: item.DocID, // Optionally store the DocID for grouping purposes
      });
      currentDocID = item.DocID;
    } else {
      // Increment the serial number for same DocID
      serialNumber++;
    }

    // Add the actual data row with the serial number
    groupedData.push({ ...item, serialNumber });
  });

  return groupedData;
};

const DebtorsList = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [showData, setShowData] = useState(false); // To control table display
  const [isLoading, setIsLoading] = useState(false); // Loader state
  const [showLoader, setShowLoader] = useState(false); // To control loader display
  const [selectedFilter, setSelectedFilter] = useState(1); // Track selected filter type, initial value "0" for All

  const current = useLocation();
  const currentPage = current.pathname.split("/")[1];
  const targetPage = getCurrentPageData(currentPage);

  const [rows, setRows] = useState([]);

  // Handle the show button click
  const handleShowData = async (e) => {
    e.preventDefault();

    const Username = user[0]?.UserID;
    const Depot = user[0]?.DpCode;
    const FormKey = targetPage?.FormKey;
    const BranchCode = branch[0]?.BranchCode;

    const requestBody = {
      Depot,
      BranchCode,
      Username,
      FormKey,
      PartyName: "", // Assuming party name is not needed as we are filtering by agent
      AgentName: "",
      ItemGroup: "",
      ItemName: "",
      Date1: formatDate(startDate),
      Date2: formatDate(endDate),
      RptCode: selectedFilter,
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
        token: localStorage.getItem("token"),
      },
    };

    try {
      const response = await axios.post(
        `${BASE_URL}/dashboard/web-reports`,
        requestBody,
        config
      );
      console.log(response.data.data);
      const AllDebtors = response.data.data.map((debtor) => ({
        ...debtor,
        Amount: Math.abs(parseFloat(debtor.Amount)),
        extra: parseFloat(debtor.Amount) > 0 ? "Dr" : "Cr",
      }));
      setRows([...AllDebtors, calculateTotalRow(AllDebtors)]);
    } catch (error) {
      alert("Something went wrong!!!", error);
    } finally {
      setShowLoader(false);
    }
  };

  const handleFilterChange = (event) => {
    const value = event.target.value;
    setSelectedFilter(value);
    setRows([]);
  };

  const calculateTotalRow = (rows) => {
    const totalAmount = rows.reduce((sum, row) => {
      return (
        sum +
        (row.extra === "Dr" ? parseFloat(row.Amount) : -parseFloat(row.Amount))
      );
    }, 0);
    return {
      Particulars: "Total",
      Amount: Math.abs(totalAmount.toFixed(2)),
      extra: totalAmount > 0 ? "Dr" : "Cr",
      isTotalRow: true, // Add a flag to identify the total row
    };
  };

  const columns = [
    {
      Header: "S No",
      Cell: ({ row }) => (
        <div style={{ textAlign: "center" }}>
          {row.original.isTotalRow ? "" : row.index + 1}
        </div>
      ),
      isInitiallyVisible: true,
    },
    {
      Header: "Particular/Name",
      accessor: "Particulars",
      isInitiallyVisible: true,
    },
    {
      accessor: "Amount",
      Header: "Amount",
      Cell: ({ value }) => (
        <div style={{ textAlign: "right" }}>
          {typeof value === "number" ? value.toFixed(2) : value}
        </div>
      ),
      isInitiallyVisible: true,
    },
    {
      Header: "",
      accessor: "extra",
      Cell: ({ value }) => <div style={{ textAlign: "center" }}>{value}</div>,
      isInitiallyVisible: true,
    },
  ];

  const filterOptions = [
    { CtCode: "0", CtName: "Agentwise" },
    { CtCode: "1", CtName: "Partywise" },
    { CtCode: "2", CtName: "Citywise" },
    { CtCode: "3", CtName: "Statewise" },
  ];
  console.log(rows);
  return (
    <div className='p-5'>
      {isLoading && <Loader />} {/* Show loader when loading */}
      <h2 className='text-sm font-semibold mb-4'>{targetPage?.FormName}</h2>
      <form className='grid grid-cols-3 gap-2'>
        <div>
          <label className='block font-medium'>Filter</label>
          <select
            value={selectedFilter}
            onChange={handleFilterChange}
            className='w-full p-1 border rounded text-xs'
          >
            {filterOptions.map((category, index) => (
              <option key={index} value={category.CtCode}>
                {category.CtName}
              </option>
            ))}
          </select>
        </div>

        <div className='flex flex-col'>
          <label className='block font-medium text-sm'>Date</label>
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            className='w-full p-1 border rounded text-xs'
            dateFormat='dd/MM/yyyy'
            maxDate={new Date()}
          />
        </div>

        {/* Show Button */}
        <div className='w-full'>
          <button
            type='button'
            onClick={handleShowData}
            className='px-4 py-2 text-xs bg-blue-600 text-white rounded w-full mt-4'
          >
            Show
          </button>
        </div>
      </form>
      {/* Conditionally render the Stock Summary Table */}
      <div className='mt-5'>
        <Table columns={columns} data={rows} />
      </div>
    </div>
  );
};

export default DebtorsList;
