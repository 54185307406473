import React, { useState } from "react";

const BillCancellation = () => {
  // State to manage the selected category and invoice number
  const [category, setCategory] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [invoiceData, setInvoiceData] = useState(null);
  // Mock function to fetch invoice details (in real application, you'd use API call here)
  const fetchInvoiceDetails = (invoiceNumber) => {
    console.log(invoiceNumber);
    // Mock data for invoice details
    const data = {
      docId: `DOC ${invoiceNumber}`,
      invoiceNumber: invoiceNumber,
      invoiceDate: "2024-10-01",
      partyName: "ABC Enterprises",
    };
    setInvoiceData(data); // Set the invoice data after fetching
  };

  // Handle invoice number input
  const handleInvoiceNumberChange = (e) => {
    setInvoiceNumber(e.target.value);
  };

  // Handle the button click to fetch invoice details
  const handleFetchInvoice = () => {
    if (invoiceNumber) {
      fetchInvoiceDetails(invoiceNumber);
    } else {
      alert("Please enter a valid Invoice Number.");
    }
  };

  // Handle cancel button click
  const handleCancel = (bill) => {
    alert("Bill has been cancelled.", bill);
    // Logic to cancel the bill here (API call or state update)
    setInvoiceData(null); // Clear the invoice data after cancellation
    setInvoiceNumber(""); // Reset invoice number
  };

  return (
    <div className='p-5'>
      <h2 className='text-sm font-bold mb-4'>Bill Cancellation</h2>
      <div className='grid grid-cols-2 md:grid-cols-3 gap-5'>
        {/* Category Dropdown */}
        <div className='mb-4'>
          <label className='block text-sm font-medium mb-1'>Category</label>
          <select
            className='w-full border p-1 rounded-lg text-xs'
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          >
            <option value=''>Select Category</option>
            <option value='Tax Invoice'>Tax Invoice</option>
            <option value='Credit Note Against Sale'>
              Credit Note Against Sale
            </option>
          </select>
        </div>

        {/* Invoice Number Input */}
        <div className='mb-4'>
          <label className='block text-sm font-medium mb-1'>
            Invoice Number
          </label>
          <input
            type='text'
            className='w-full border p-1 rounded-lg text-xs'
            value={invoiceNumber}
            onChange={handleInvoiceNumberChange}
            placeholder='Enter Invoice Number'
          />
        </div>
        <button
          onClick={handleFetchInvoice}
          className='w-full  text-sm bg-blue-500 mt-5 text-white py-2 rounded-lg mb-5 '
        >
          Fetch Invoice Details
        </button>
      </div>

      {/* Display Invoice Details */}
      {invoiceData && (
        <div className='bg-gray-100 p-4 rounded-lg border '>
          <p className='text-xs mb-2'>
            <span className='font-semibold'>Doc ID: </span>
            {invoiceData.docId}
          </p>
          <p className='text-xs mb-2'>
            <span className='font-semibold'>Invoice Number: </span>
            {invoiceData.invoiceNumber}
          </p>
          <p className='text-xs mb-2'>
            <span className='font-semibold'>Invoice Date: </span>
            {invoiceData.invoiceDate}
          </p>
          <p className='text-xs mb-4'>
            <span className='font-semibold'>Party Name: </span>
            {invoiceData.partyName}
          </p>

          <button
            onClick={handleCancel}
            className='w-full  text-sm bg-red-500 text-white py-2 rounded-lg'
          >
            Cancel Bill
          </button>
        </div>
      )}
    </div>
  );
};

export default BillCancellation;
